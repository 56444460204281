import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";
import DOMPurify from "dompurify";

export default function Profile() {
  const token = sessionStorage.getItem("token");

  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();

  let userName = sessionStorage.getItem("userName");
  const navigate = useNavigate();

  const [details, setDetails] = useState({});
  const [WalletAddress, setWalletAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const Profile = async () => {
    try {
      let res = await API.get(`Userprofile_Details?uid=${userId}`, {
        headers: {
          Authorization: token,
        },
      });
      res = res.data.data[0];
      console.log("res-->", res);
      setWalletAddress(res.WalletAddress);
      setPhone(res.mobile);
      setEmail(res.email);

      console.log("Profile+>", res);
      setDetails(res);
    } catch (e) {
      console.log("Somthing error in Profile API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Profile();
  }, []);

  // console.log("123",details)

  const Profile_Update = async () => {
    try {
      let res = await API.post(
        "Update_User_Profile_New",
        {
          uid: userId,
          Name: details.f_name,
          Email: Email,
          Mobile: Phone,
          WalletAddress: WalletAddress,
          Password: Password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Profile_Update", res.data.data.Result);

      if (res.data.data.Result === "Update Successfull") {
        toast.success(res.data.data.Result);
      } else {
        toast.error(res.data.data.Result);
      }
    } catch (e) {
      console.log("Somthig error in Profile Update api ", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 p-1 px-2">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Profile</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card cp-card mt-4 m-2 py-2 col-lg-6">
                <div className="px-4 mb-3">
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">User Id</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="User Id"
                      value={userId}
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Name</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Name"
                      value={details.f_name}
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="Reg">Registration Date</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="Reg"
                      placeholder="Registration Date"
                      value={details?.edate ? details.edate[1] : ""}
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">E-mail</label>
                    <input
                      type="email"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      value={Email}
                      onChange={(e) =>
                        setEmail(DOMPurify.sanitize(e.target.value))
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Phone</label>
                    <div style={{ display: "flex" }}>
                      <input
                        className="phone form-control"
                        type="text"
                        placeholder="Phone No"
                        value={Phone}
                        onChange={(e) =>
                          setPhone(
                            DOMPurify.sanitize(
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          )
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">USDT Address</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="USDT Address"
                      value={WalletAddress}
                      maxLength={100}
                      onChange={(e) =>
                        setWalletAddress(DOMPurify.sanitize(e.target.value))
                      }
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">
                      Transaction Password
                    </label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Transaction Password"
                      value={Password}
                      maxlength={20}
                      onChange={(e) =>
                        setPassword(DOMPurify.sanitize(e.target.value))
                      }
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn transfer-btn mt-4"
                    onClick={() => Profile_Update()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
