import React, { useEffect, useState } from "react";
import "../Deposit/Deposit.css";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchBetaWalletData,
//   selectBetaWalletInfo,
// } from "../../Redux/Slices/betaWalletSlice";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";

const WithDrawalGame = () => {
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  let uId = sessionStorage.getItem("userName");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const info = useSelector(selectBetaWalletInfo);
  // console.log("info", info);
  // useEffect(() => {
  //   dispatch(fetchBetaWalletData({ id: "USERID780362", token }));
  // }, []);

  const [old, setData] = useState("0.00");
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [usdamount, setusdamount] = useState("");
  const [valueData, setValueData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [select, setSelect] = useState("BankDetails");
  const [Profileotpvalid, setProfileOTPValid] = useState("0");
  const [Profileotp, setProfileOTP] = useState("");

  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        console.log("account", accounts[0]);
        setInputValue(accounts[0]);
      } else {
        console.log("eee", "MetaMask not found");
      }
    } catch (error) {
      console.error("ggg", error);
    }
  };

  // useEffect(() => {
  //   connectToMetaMask();
  // }, []);

  const [walletbalance, setwalletbalance] = useState("");
  
  const DashboardValues = async () => {
    try {
      let resnetbal = await API.post(
        `/getbetawallet`,
        { username: uId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Live_Rate1", resnetbal);
      setwalletbalance(resnetbal.data.data[0][0].MainWalletBal);
    } catch (e) {
      console.log("Error While Fatch Dashboard API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const hendelclick = (event) => {
    let value = event.target.value;
    let sanitizedValue = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
    sanitizedValue = sanitizedValue.replace(/[^0-9]/g, "");
    setAmount(sanitizedValue);
    setusdamount((parseFloat(sanitizedValue)/ parseFloat(90.00)).toFixed(2));
    // ConverAmount(sanitizedValue);
  };

  const ConverAmount = async (value) => {
    try {
      let res = await API.get(
        `WithdrawlConversationAmount?PtcAmount=${value}&username=${uId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      res = res.data.data[0];
      console.log("ConverAmount", res);
      setValueData(res);
    } catch (e) {
      console.log("Something Error in ConvertAmount API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const Live_Rate = async () => {
    try {
      let res = await API.post(
        `/getbetawallet`,
        { username: uId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("old", res);
      res = res.data.data[0][0].totalnetbal;
      setData(res);
    } catch (e) {
      console.log("Error While Fatch  Live_RateAPI", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };
  const SendOtpProfile_API = async () => {
    try {
      let res = await API.post(
        "updateWithdrawlOtp_Game",
        {
          uid: uId,
          Password: password,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("SendOtp_API", res);
      res = res?.data?.data;

      if (res === "Successfull") {
        setProfileOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setProfileOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
    }
  };

  const hendleOTPProfile = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setProfileOTP(newValue);
  };
  useEffect(() => {
    Live_Rate();
    DashboardValues();
  }, []);

  const Withdrwal_API = async () => {
    try {
      let res = await API.post(
        "UserWithdrawl_NewGame",
        {
          uid: uId,
          amount: amount,
          type: select,
          password: password,
          otp: Profileotp
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Withdrwal", res.data.data.result);
      // setscoreboard_data(res.data)
      if (res.data.data.result === "SUCCESSFUL") {
        toast.success(res.data.data.result);
        // Live_Rate();
        window.location.reload();
      } else {
        toast.error(res.data.data.result);
      }
      //}
    } catch (error) {
      console.log("Something Error in Withdrwal API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10 com-h">
      <div className="row ">
        <div className="col-12 px-2 p-1">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Game Withdrawal</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className=" card deposit-card mg-lg-4 mt-2 py-2 col-lg-6">
                <div className="px-4">
                  {/* <form> */}
                  <div className="row mt-2">
                    <div className="col-md-12 px-1">
                      <div className="form-group text-start">
                        <label htmlFor="avl_bl ">Available Balance</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="avl_bl"
                          placeholder="Available Balance"
                          value={walletbalance}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-12 px-1">
                      <div className="form-group text-start">
                        <label htmlFor="exampleInputEmail1">
                          Select Withdrawl Type
                        </label>
                        <select
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          onChange={(e) => setSelect(e.target.value)}>
                          <option>Select Withdrawl Type</option>
                          <option value="USDT">USDT</option>
                          <option value="BankDetails">Bank Details</option>
                        </select>
                      </div>
                    </div>
                    
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-12 px-1">
                      <div className="form-group text-start">
                        <label htmlFor="amount">Enter Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="amount"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => hendelclick(e)}
                          maxLength={10}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {select == "USDT" ? (
                      <>
                        <div className="col-md-12 px-1">
                          <div className="form-group text-start">
                            <label htmlFor="exampleInputEmail1">
                              USD Amount
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="exampleInputEmail1"
                              placeholder="Calculated USD Amount"
                              value={usdamount}
                              disabled
                              maxLength={10}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )} 
                  </div>

                  <div className="row mt-1">
                    {/* <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">AGC Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          placeholder="Calculated AGC Amount"
                          value={valueData.AGCAmount}
                          disabled
                          maxLength={10}
                        />
                      </div>
                    </div> */}

                    <div className="col-md-12 px-1 text-start">
                      <label htmlFor="pass">Transaction Password</label>
                      <input
                        className="form-control mt-2"
                        id="pass"
                        type="password"
                        placeholder="Transaction Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  {Profileotpvalid === "1" ? (
                    <>
                      <div className="form-group mt-2">
                        <label>OTP</label>
                        <input
                          type="text"
                          name="otp"
                          maxLength={8}
                          className="form-control mt-2"
                          required=""
                          title="OTP is required"
                          placeholder="OTP"
                          onChange={(e) => hendleOTPProfile(e.target.value)}
                          value={Profileotp}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {Profileotpvalid === "1" ? (
                    <button
                      type="submit"
                      className="btn transfer-btn mt-4"
                      onClick={() => Withdrwal_API()}
                    >
                      Withdrawal Balance
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn transfer-btn mt-4"
                      onClick={() => SendOtpProfile_API()}
                    >
                      GET OTP
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithDrawalGame;