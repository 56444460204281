import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Modal from "../TeenPatti/Modal";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { useDispatch, useSelector } from "react-redux";
import cardBlank from "../../Media/DTL2020/backcard.webp";
import { card52War } from "../../Utility/52CardImages.js";

export default function Card32B() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showresultModal, setShowresultModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [betDetails, setbetDetails] = useState({});
  // const { casinobetHistory } = useSelector((state) => state.casinobetHistory);
  const [betIndex, setBetIndex] = useState("");
  const [rulesModal, setRulesModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const resultMapping = {
    1: "8",
    2: "9",
    3: "10",
    4: "11",
  };

  
  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound} </div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
                setTrophy("");
                setCasinoGameResult([]);
              }}
            />
          </div>
          <div className="mb-1">
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  Player 8{trophy == 8 && <FaTrophy className="trophy-icon" />}
                </div>
                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[0]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[0].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1">
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 9</div>
                  {trophy == 9 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[1]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[1].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 10</div>
                  {trophy == 10 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[2]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[2].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 11</div>
                  {trophy == 11 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[3]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[3].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";
  
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("32cardB==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0]);
        // console.log("32cardB", parsedData[0].reqResponse);
        setResults(parsedData[0].resultUrlResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket32CardB");

    cricketMatchesSocket.on("GetCasinoGameSocket32CardB_FromAPI", (data) => {
      // console.log("dtl socket1==>", data);
      if (data.data.success == true) {
        // console.log("parsed", data);
        setreqRes(data);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  // console.log(
  //   "gstatus",
  //   reqRes?.data?.data?.t2[0].gstatus,
  //   reqRes?.data?.data?.t1[0]?.autotime,
  //   reqRes?.data?.data?.t1[0]?.mid
  // );

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: reqRes?.data?.data?.t1[0]?.mid,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const parseCardData = (cardData) => {
    return cardData.split(",").map((card) => {
      const cardName = card.trim(); // Extract card name and trim any whitespace
      return cardName;
    });
  };

  const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  // console.log("arr", arrayData);

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev ">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                    <span onClick={() => setRulesModal(true)}>Rules</span>
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                  <div
                    className="container"
                    style={{
                      color: "white",
                      marginLeft: "-1px",
                      marginTop: "-150px",
                    }}
                  >
                    {/* <div className="timer_container position-absolute"  > */}
                    {arrayData[0] !== "1" && (
                      <>
                        <span style={{ fontSize: "15px" }}>
                          PLAYER 8:{reqRes?.data?.data?.t1[0]?.C1}
                        </span>
                        <div>
                          {[0, 4].map((key, index) => {
                            const cardId = arrayData[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId)}
                                  style={{
                                    width: "20px",
                                    height: "auto",
                                    marginLeft: "20px",
                                  }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </>
                    )}

                    <span>
                      {arrayData[1] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 9:{reqRes?.data?.data?.t1[0]?.C2}
                          </span>
                          <div>
                            {[1, 5].map((key, index) => {
                              const cardId = arrayData[key]; // Access the zeroth index of arrayData
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>
                    {/* PLAYER 10:22 */}

                    <span>
                      {arrayData[2] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 10:{reqRes?.data?.data?.t1[0]?.C3}
                          </span>
                          <div>
                            {[2, 6].map((key, index) => {
                              const cardId = arrayData[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>

                    {/*PLAYER 11:20  */}

                    <span>
                      {arrayData[3] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 11:{reqRes?.data?.data?.t1[0]?.C4}
                          </span>
                          <div>
                            {[3, 7].map((key, index) => {
                              const cardId = arrayData[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>

                    {/* </div> */}
                  </div>
                </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                      <div
                        className="timer_container position-absolute"
                        style={{ right: "40px", bottom: "15px" }}>
                        <button
                          class="btn btn-primary"
                          style={{ cursor: "default" }}>
                          <span>
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="cardthiertytwoB cards32b p-1">
                <div className="casino-detail">
                  <div className="casino-table">
                    <div className="casino-table-box">
                      <div className="casino-table-left-box">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back">Back</div>
                          <div className="casino-odds-box lay">Lay</div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Player 8</div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 1 && (
                                      <span
                                        className={`${
                                          ele.number == 1 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[0]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[0]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[0]?.b1}
                                </span>
                              </div> 
                            )}
                            {reqRes?.data?.data?.t2[0]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">  
                                  {reqRes?.data?.data?.t2[0]?.l1}
                                </span>
                              </div> 
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true); 
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.l1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    5
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[0]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Player 9</div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 2 && (
                                      <span
                                        className={`${
                                          ele.number == 2 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[1]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[1]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[1]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[1]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[1]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.l1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    6
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[1]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Player 10
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 3 && (
                                      <span
                                        className={`${
                                          ele.number == 3 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[2]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[2]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.b1,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    3
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[2]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[2]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[2]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.l1,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    7
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[2]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Player 11
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 4 && (
                                      <span
                                        className={`${
                                          ele.number == 4 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[3]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[3]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.b1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    4
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[3]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[3]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[3]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.l1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    8
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[3]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-box-divider"></div>
                      <div className="casino-table-right-box">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back">Odd</div>
                          <div className="casino-odds-box back">Even</div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row ">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Player 8</div>
                            </div>
                            {reqRes?.data?.data?.t2[4]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[4]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.b1,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    9
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[4]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 5 && (
                                        <span
                                          className={`${
                                            ele.number == 5 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 5 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[5]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.b1,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    13
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[5]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 9 && (
                                        <span
                                          className={`${
                                            ele.number == 9 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 9 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Player 9</div>
                            </div>
                            {reqRes?.data?.data?.t2[6]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[6]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[6]?.b1,
                                    reqRes?.data?.data?.t2[6]?.nat,
                                    10
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[6]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 6 && (
                                        <span
                                          className={`${
                                            ele.number == 6 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 6 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[7]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[7]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[7]?.b1,
                                    reqRes?.data?.data?.t2[7]?.nat,
                                    14
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[7]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 10 && (
                                        <span
                                          className={`${
                                            ele.number == 10 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 10 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Player 10
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[8]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[8]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[8]?.b1,
                                    reqRes?.data?.data?.t2[8]?.nat,
                                    11
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[8]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 7 && (
                                        <span
                                          className={`${
                                            ele.number == 7 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 7 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[9]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[9]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[9]?.b1,
                                    reqRes?.data?.data?.t2[9]?.nat,
                                    15
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[9]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 11 && (
                                        <span
                                          className={`${
                                            ele.number == 11 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 11 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Player 11
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[10]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[10]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[10]?.b1,
                                    reqRes?.data?.data?.t2[10]?.nat,
                                    12
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[10]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 8 && (
                                        <span
                                          className={`${
                                            ele.number == 8 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 8 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[11]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[11]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[11]?.b1,
                                    reqRes?.data?.data?.t2[11]?.nat,
                                    16
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                                }}
                              >
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[11]?.b1}
                                </span>
                                <span>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 12 && (
                                        <span
                                          className={`${
                                            ele.number == 12 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.number == 12 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="casino-table-box mt-3">
                      <div className="casino-table-left-box">
                        <div className="casino-table-header">
                          <div class="casino-nation-detail"></div>
                          <div class="casino-odds-box back">Back</div>
                          <div class="casino-odds-box lay">Lay</div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row">
                            <div class="casino-nation-detail">
                              <div class="casino-nation-name">
                                Any 3 Card Black
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 13 && (
                                      <span
                                        className={`${
                                          ele.number == 13 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == 13 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[12]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[12]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.b1,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    17
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[12]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[12]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[12]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.l1,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    18
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[12]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Any 3 Card Red
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 14 && (
                                      <span
                                        className={`${
                                          ele.number == 14 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[13]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[13]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[13]?.b1,
                                    reqRes?.data?.data?.t2[13]?.nat,
                                    19
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[13]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[13]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[13]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[13]?.l1,
                                    reqRes?.data?.data?.t2[13]?.nat,
                                    20
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[13]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                Two Black Two Red
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 15 && (
                                      <span
                                        className={`${
                                          ele.number == 15 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[26]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[26]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[26]?.b1,
                                    reqRes?.data?.data?.t2[26]?.nat,
                                    21
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[26]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[26]?.b1}
                                </span>
                              </div>
                            )}
                            {reqRes?.data?.data?.t2[26]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[26]?.l1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box lay"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[26]?.l1,
                                    reqRes?.data?.data?.t2[26]?.nat,
                                    22
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[26]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[26]?.l1}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-box-divider"></div>
                      <div className="casino-table-right-box cards32total">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back">Back</div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row ">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                8 &amp; 9 Total
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        className={`${
                                          ele.number == 16 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[24]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[24]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(1);
                                  handleModal(
                                    reqRes?.data?.data?.t2[24]?.b1,
                                    reqRes?.data?.data?.t2[24]?.nat,
                                    23
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[24]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[24]?.b1}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                10 &amp; 11 Total
                              </div>
                              <span>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 17 && (
                                      <span
                                        className={`${
                                          ele.number == 17 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}
                              </span>
                            </div>
                            {reqRes?.data?.data?.t2[25]?.gstatus != "ACTIVE" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[25]?.b1}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="casino-odds-box back"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[25]?.b1,
                                    reqRes?.data?.data?.t2[25]?.nat,
                                    24
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[25]?.sid);
                                }}>
                                <span className="casino-odds">
                                  {reqRes?.data?.data?.t2[25]?.b1}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="casino-table-full-box mt-3 card32numbers">
                      <h4 className="w-100 text-center mb-2">
                        <b>{reqRes?.data?.data?.t2[14]?.b1}</b>
                      </h4>
                      <div className="card32numbers-container">
                        {reqRes?.data?.data?.t2[14]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">1</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[14]?.b1,
                                reqRes?.data?.data?.t2[14]?.nat,
                                25
                              );
                              setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                            }}
                          >
                            <span className="casino-odds">1</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 18 && (
                                    <span
                                      className={`${
                                        ele.number == 18 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 18 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[15]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">2</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[15]?.b1,
                                reqRes?.data?.data?.t2[15]?.nat,
                                26
                              );
                              setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                            }}
                          >
                            <span className="casino-odds">2</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 19 && (
                                    <span
                                      className={`${
                                        ele.number == 19 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 19 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[16]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">3</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[16]?.b1,
                                reqRes?.data?.data?.t2[16]?.nat,
                                27
                              );
                              setBetIndex(reqRes?.data?.data?.t2[16]?.sid);
                            }}
                          >
                            <span className="casino-odds">3</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 20 && (
                                    <span
                                      className={`${
                                        ele.number == 20 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 20 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[17]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">4</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[17]?.b1,
                                reqRes?.data?.data?.t2[17]?.nat,
                                28
                              );
                              setBetIndex(reqRes?.data?.data?.t2[17]?.sid);
                            }}
                          >
                            <span className="casino-odds">4</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 21 && (
                                    <span
                                      className={`${
                                        ele.number == 21 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 21 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[18]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">5</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[18]?.b1,
                                reqRes?.data?.data?.t2[18]?.nat,
                                29
                              );
                              setBetIndex(reqRes?.data?.data?.t2[18]?.sid);
                            }}
                          >
                            <span className="casino-odds">5</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 22 && (
                                    <span
                                      className={`${
                                        ele.number == 22 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 22 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[19]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">6</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[19]?.b1,
                                reqRes?.data?.data?.t2[19]?.nat,
                                30
                              );
                              setBetIndex(reqRes?.data?.data?.t2[19]?.sid);
                            }}
                          >
                            <span className="casino-odds">6</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 23 && (
                                    <span
                                      className={`${
                                        ele.number == 23 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 23 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[20]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">7</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[20]?.b1,
                                reqRes?.data?.data?.t2[20]?.nat,
                                32
                              );
                              setBetIndex(reqRes?.data?.data?.t2[20]?.sid);
                            }}
                          >
                            <span className="casino-odds">7</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 24 && (
                                    <span
                                      className={`${
                                        ele.number == 24 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 24 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[21]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">8</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[21]?.b1,
                                reqRes?.data?.data?.t2[21]?.nat,
                                31
                              );
                              // setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                          >
                            <span className="casino-odds">8</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 25 && (
                                    <span
                                      className={`${
                                        ele.number == 25 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 25 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[22]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">9</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[22]?.b1,
                                reqRes?.data?.data?.t2[22]?.nat,
                                33
                              );
                              // setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                          >
                            <span className="casino-odds">9</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 26 && (
                                    <span
                                      className={`${
                                        ele.number == 26 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 26 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[23]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[23]?.b1,
                                reqRes?.data?.data?.t2[23]?.nat,
                                34
                              );
                              // setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                          >
                            <span className="casino-odds">0</span>
                            <span>
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 27 && (
                                    <span
                                      className={`${
                                        ele.number == 27 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 27 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* +++++++++++++++++++++++++++++++++++++++++ */}
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(resultMapping[result.result]);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          <>{resultMapping[result.result]}</>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
