import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import Modal from "../TeenPatti/Modal";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

function Bollywoodcasino() {
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const resultMapping = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
  };
  // console.log("results", results);

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));
        // console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].reqResponse);
        // console.log("status", reqRes?.data?.data?.t2[0]?.gstatus);

        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      betDetails.nat === filteredData[0].nat //&&
        ? // betDetails.multipler === filteredData[0].rate
          setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                {/* <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div> */}

                <div className="d-flex">
                  {casinoGameResult[0] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[0].id}>
                      <img
                        src={casinoGameResult[0].image}
                        className="img-fluid"
                        alt={`Player B Card 1`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  // console.log("history", history);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <div className="col-lg-10">
        <div className="row home">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">Bollywood Casino</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    {/* Round ID: 2024021633648 | Left Time: 56 | Rules */}
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time:{reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{ color: "white",marginLeft:"-1px",  marginTop:'-130px'}}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span></span>
                      <div>  
                        {["C1"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img 
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "30px", height: "auto" }}
                              />
                            </span>   
                          ) : null;
                        })}
                      </div>
                      {/* <span>
                      <span>Player B</span>
                      <div>
                        {["C3", "C4"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}
                      {/* Board */}

                      {/* <span>
                      <span>Board</span> 
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}


                     

                      {/* </div> */}
                    </div>
                  </div>
                  
                  <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}>
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "40px", bottom: "15px" }}>
                      <button
                        class="btn btn-primary"
                        style={{ cursor: "default" }}>
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 py-2">
                  <div className="dragon-column1 cards-thirteen-div ">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            A.{reqRes?.data?.data?.t2[0]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[0]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5  suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.b1,
                                      reqRes?.data?.data?.t2[0]?.nat,
                                      1
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[0]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[0]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn4 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.l1,
                                      reqRes?.data?.data?.t2[0]?.nat,
                                      7
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[0]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 1 && (
                                  <span
                                    className={`${
                                      ele.number == 1 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 1 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            B.{reqRes?.data?.data?.t2[1]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[1]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.b1,
                                      reqRes?.data?.data?.t2[1]?.nat,
                                      2
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[1]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[1]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.l1,
                                      reqRes?.data?.data?.t2[1]?.nat,
                                      8
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[1]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 2 && (
                                  <span
                                    className={`${
                                      ele.number == 2 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 2 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            C. {reqRes?.data?.data?.t2[2]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[2]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.b1,
                                      reqRes?.data?.data?.t2[2]?.nat,
                                      3
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[2]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[2]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.l1,
                                      reqRes?.data?.data?.t2[2]?.nat,
                                      9
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[2]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 3 && (
                                  <span
                                    className={`${
                                      ele.number == 3 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 3 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 py-2">
                  <div className="dragon-column1 ">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            D.{reqRes?.data?.data?.t2[3]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[3]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3]?.b1,
                                      reqRes?.data?.data?.t2[3]?.nat,
                                      4
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[3]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[3]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3]?.l1,
                                      reqRes?.data?.data?.t2[3]?.nat,
                                      10
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[3]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 4 && (
                                  <span
                                    className={`${
                                      ele.number == 4 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 4 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            E.{reqRes?.data?.data?.t2[4]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[4]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[4]?.b1,
                                      reqRes?.data?.data?.t2[4]?.nat,
                                      5
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[4]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[4]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[4]?.l1,
                                      reqRes?.data?.data?.t2[4]?.nat,
                                      11
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[4]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 5 && (
                                  <span
                                    className={`${
                                      ele.number == 5 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 5 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            F.{reqRes?.data?.data?.t2[5]?.nat}{" "}
                          </div>

                          <div className="d-flex justify-content-center">
                            <div className="">
                              {reqRes?.data?.data?.t2[5]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="back-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="back-btn blue-grey-btn5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[5]?.b1,
                                      reqRes?.data?.data?.t2[5]?.nat,
                                      6
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[5]?.b1}
                                </button>
                              )}
                            </div>
                            &nbsp;
                            <div className="">
                              {reqRes?.data?.data?.t2[5]?.gstatus !==
                              "ACTIVE" ? (
                                <div className="lay-btn blue-grey-btn5 suspended-box">
                                  {/* <i className="fa fa-lock" /> */}
                                </div>
                              ) : (
                                <button
                                  className="lay-btn blue-grey-btn4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[5]?.l1,
                                      reqRes?.data?.data?.t2[5]?.nat,
                                      12
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                  }}>
                                  {reqRes?.data?.data?.t2[5]?.l1}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 6 && (
                                  <span
                                    className={`${
                                      ele.number == 6 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 6 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 py-2">
                  <div className="dragon-column1 ">
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            {reqRes?.data?.data?.t2[13]?.b1}
                          </div>
                          <div className="">
                            {reqRes?.data?.data?.t2[13]?.gstatus !== "ACTIVE" ||
                            reqRes?.data?.data?.t2[13]?.gstatus ===
                              undefined ? (
                              <>
                                <button className="blue-grey-btn mx-auto overs suspended-box"></button>
                              </>
                            ) : (
                              <>
                                {" "}
                                <button
                                  className="blue-grey-btn mx-auto overs"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[13]?.b1,
                                      reqRes?.data?.data?.t2[13]?.nat,
                                      15
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[13]?.sid
                                    );
                                  }}>
                                  {reqRes?.data?.data?.t2[13]?.nat}
                                </button>
                              </>
                            )}
                          </div>
                          <div className="d-flex justify-content-center dragon-heading" />
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 8 && (
                                  <span
                                    className={`${
                                      ele.number == 8 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 8 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            {reqRes?.data?.data?.t2[14]?.b1}
                          </div>
                          <div className="">
                            {reqRes?.data?.data?.t2[14]?.gstatus !== "ACTIVE" ||
                            reqRes?.data?.data?.t2[14]?.gstatus ===
                              undefined ? (
                              <>
                                <button className="blue-grey-btn mx-auto overs suspended-box"></button>
                              </>
                            ) : (
                              <>
                                {" "}
                                <button
                                  className="blue-grey-btn mx-auto overs"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[14]?.b1,
                                      reqRes?.data?.data?.t2[14]?.nat,
                                      16
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[14]?.sid
                                    );
                                  }}>
                                  {reqRes?.data?.data?.t2[14]?.nat}
                                </button>
                              </>
                            )}
                          </div>
                          <div className="d-flex justify-content-center dragon-heading" />
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 9 && (
                                  <span
                                    className={`${
                                      ele.number == 9 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 9 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 py-2">
                  <div className="dragon-column ">
                    <div className="row">
                      <div className="col-6 ">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            {reqRes?.data?.data?.t2[7]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[7]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[7]?.gstatus === undefined ? (
                            <>
                              <button className="blue-grey-btn mx-auto overs suspended-box">
                                {/* <i className="fa fa-lock" /> */}
                              </button>
                            </>
                          ) : (
                            <>
                              {" "}
                              <button
                                className="blue-grey-btn mx-auto overs"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[7]?.b1,
                                    reqRes?.data?.data?.t2[7]?.nat,
                                    17
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                                }}>
                                {reqRes?.data?.data?.t2[7]?.nat}
                              </button>
                            </>
                          )}
                          <div className="d-flex justify-content-center dragon-heading" />
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 10 && (
                                  <span
                                    className={`${
                                      ele.number == 10 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 10 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 ">
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            {reqRes?.data?.data?.t2[8]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[8]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[8]?.gstatus === undefined ? (
                            <>
                              <button className="blue-grey-btn mx-auto overs suspended-box">
                                {/* <i className="fa fa-lock" /> */}
                              </button>
                            </>
                          ) : (
                            <>
                              {" "}
                              <button
                                className="blue-grey-btn mx-auto overs"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[8]?.b1,
                                    reqRes?.data?.data?.t2[8]?.nat,
                                    18
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                                }}>
                                {reqRes?.data?.data?.t2[8]?.nat}
                              </button>
                            </>
                          )}
                          <div className="d-flex justify-content-center dragon-heading" />
                          <div className="casino-nation-book text-center w-100">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 11 && (
                                  <span
                                    className={`${
                                      ele.number == 11 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}>
                                    {ele.number == 11 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 py-2">
                  <div className="py-0">
                    <div className="d-flex justify-content-center">
                      {reqRes?.data?.data?.t2[9]?.b1}
                    </div>
                    <div className="cards-thirteen-div d-flex flex-wrap justify-content-center">
                      <div className="card-column-img-div  d-flex flex-column mx-1">
                        <div className="position-relative">
                          {reqRes?.data?.data?.t2[9]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[9]?.gstatus === undefined ? (
                            <>
                              <div className="suspended__2">
                                <i className="fa fa-lock" />
                              </div>
                              <img
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/J.png"
                                alt="Card 1"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[9]?.b1,
                                    reqRes?.data?.data?.t2[9]?.nat,
                                    19
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                }}
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/J.png"
                                alt="Card 1"
                              />
                            </>
                          )}
                        </div>
                        <div className="card-zero d-flex justify-content-center" />
                        <div className="casino-nation-book text-center w-100">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 12 && (
                                <span
                                  className={`${
                                    ele.number == 12 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 12 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="card-column-img-div  d-flex flex-column mx-1">
                        <div className="position-relative">
                          {reqRes?.data?.data?.t2[10]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[10]?.gstatus === undefined ? (
                            <>
                              <div className="suspended__2">
                                <i className="fa fa-lock" />
                              </div>
                              <img
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/Q.png"
                                alt="Card 1"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[10]?.b1,
                                    reqRes?.data?.data?.t2[10]?.nat,
                                    20
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                                }}
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/Q.png"
                                alt="Card 2"
                              />
                            </>
                          )}
                        </div>
                        <div className="card-zero d-flex justify-content-center" />
                        <div className="casino-nation-book text-center w-100">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 13 && (
                                <span
                                  className={`${
                                    ele.number == 13 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 13 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="card-column-img-div  d-flex flex-column mx-1">
                        <div className="position-relative">
                          {reqRes?.data?.data?.t2[11]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[11]?.gstatus === undefined ? (
                            <>
                              <div className="suspended__2">
                                <i className="fa fa-lock" />
                              </div>
                              <img
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/K.png"
                                alt="Card 1"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[11]?.b1,
                                    reqRes?.data?.data?.t2[11]?.nat,
                                    21
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                                }}
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/K.png"
                                alt="Card 3"
                              />
                            </>
                          )}
                        </div>
                        <div className="card-zero d-flex justify-content-center" />
                        <div className="casino-nation-book text-center w-100">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 14 && (
                                <span
                                  className={`${
                                    ele.number == 14 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 14 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="card-column-img-div  d-flex flex-column mx-1">
                        <div className="position-relative">
                          {reqRes?.data?.data?.t2[12]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[12]?.gstatus === undefined ? (
                            <>
                              <div className="suspended__2">
                                <i className="fa fa-lock" />
                              </div>
                              <img
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/A.png"
                                alt="Card 1"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.b1,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    22
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                }}
                                className="card-column-img"
                                src="https://versionobj.ecoassetsservice.com/v11/static/front/img/cards/A.png"
                                alt="Card 4"
                              />
                            </>
                          )}
                        </div>
                        <div className="card-zero d-flex justify-content-center" />
                        <div className="casino-nation-book text-center w-100">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 15 && (
                                <span
                                  className={`${
                                    ele.number == 15 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 15 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {item && <>{resultMapping[result.result]}</>}
                        </div>
                      );
                    })}
                    ;
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>
          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}

          {showresultModal && <ResultsModal />}
        </div>
      </div>
    </>
  );
}

export default Bollywoodcasino;
