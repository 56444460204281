import React, { useState, useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import "./Amerakber.css";
import heart from "../../Media/heart-2.png";
import diamond from "../../Media/diamond-2.png";
import spade from "../../Media/spade-2.png";
import club from "../../Media/club-2.png";
import { useParams } from "react-router-dom";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import Spade from "../../Media/spade-2.png";
import Heart from "../../Media/heart-2.png";
import Club from "../../Media/club-2.png";
import Diamond from "../../Media/diamond-2.png";
import Modal from "../TeenPatti/Modal";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";


export default function AmarAkbar() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const cardImages = [Spade, Club, Heart, Diamond];

  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);

  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const resultMapping = {
    1: "A",
    2: "B",
    3: "C",
  };
  // console.log("results", results);

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  // console.log("thirtyTwoCardStack", thirtyTwoCardStack);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="lucky-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="d-flex">
                  {casinoGameResult[0] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[0].id}>
                      <img
                        src={casinoGameResult[0].image}
                        className="img-fluid"
                        alt={`Player A Card 0`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      //  console.log("GetCasinoGameSocket",data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("reqResponse", parsedData[0].reqResponse);
        // console.log("resultUrlResponse", parsedData[0].resultUrlResponse);

        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus == "ACTIVE" &&
      betDetails.nat === filteredData[0].nat &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{ color: "white",marginLeft:"-1px",  marginTop:'-150px'}}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span></span>
                      <div>
                        {["C1"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "30px", height: "auto" }}
                              />
                            </span>   
                          ) : null;
                        })}
                      </div>
                      {/* <span>
                      <span>Player B</span>
                      <div>
                        {["C3", "C4"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}
                      {/* Board */}

                      {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}


                     

                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                      <div
                        className="timer_container position-absolute"
                        style={{ right: "40px", bottom: "15px" }}>
                        <button
                          class="btn btn-primary"
                          style={{ cursor: "default" }}>
                          <span>
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="amerakbarantho aaa bg-white p-1">
                <div className="casino-table">
                  <div className="casino-table-box">
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name  position-relative">
                        A. {reqRes?.data?.data?.t2[0]?.nat}{" "}
                        <div className="casino-nation-book d-md-none"></div>
                      </div>

                      {reqRes?.data?.data?.t2[0]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box back position-relative suspended-box">
                          <div className="casino-odds ">
                            <span> {reqRes?.data?.data?.t2[0]?.b1} </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back position-relative "
                          onClick={() => {
                            // setShowModal(true);
                            // setBetNum(1);
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.b1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              1
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}>
                          <div className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[0]?.b1} </span>
                          </div>
                        </div>
                      )}

                      {reqRes?.data?.data?.t2[0]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box lay position-relative suspended-box">
                          <span className="casino-odds ">
                            <span> {reqRes?.data?.data?.t2[0]?.l1} </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay position-relative"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.l1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              4
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}>
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[0]?.l1} </span>
                          </span>
                        </div>
                      )}

                      <div className="casino-nation-book text-center d-none d-md-block w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        B. {reqRes?.data?.data?.t2[1]?.nat}{" "}
                        <div className="casino-nation-book d-md-none"></div>
                      </div>

                      {reqRes?.data?.data?.t2[1]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box back position-relative suspended-box">
                          <span className="casino-odds">
                            {reqRes?.data?.data?.t2[1]?.b1}
                          </span>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back position-relative"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.b1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              2
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}>
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[1]?.b1} </span>
                          </span>
                        </div>
                      )}

                      {reqRes?.data?.data?.t2[1]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box lay position-relative suspended-box">
                          <span className="casino-odds">
                            {reqRes?.data?.data?.t2[1]?.l1}{" "}
                          </span>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay position-relative "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.b1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              5
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}>
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[1]?.l1} </span>
                          </span>
                        </div>
                      )}

                      <div className="casino-nation-book text-center d-none d-md-block w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        C. {reqRes?.data?.data?.t2[2]?.nat}{" "}
                        <div className="casino-nation-book d-md-none"></div>
                      </div>

                      {reqRes?.data?.data?.t2[2]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box back position-relative suspended-box">
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[2]?.b1} </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back position-relative"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[2]?.b1,
                              reqRes?.data?.data?.t2[2]?.nat,
                              3
                            );
                            setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                          }}>
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[2]?.b1} </span>
                          </span>
                        </div>
                      )}

                      {reqRes?.data?.data?.t2[2]?.gstatus != "ACTIVE" ? (
                        <div className="casino-odds-box lay position-relative suspended-box">
                          <span className="casino-odds">
                            <span>{reqRes?.data?.data?.t2[2]?.l1} </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay position-relative"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[2]?.l1,
                              reqRes?.data?.data?.t2[2]?.nat,
                              6
                            );
                            setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                          }}>
                          <span className="casino-odds ">
                            <span>{reqRes?.data?.data?.t2[2]?.l1} </span>
                          </span>
                        </div>
                      )}

                      <div className="casino-nation-book text-center d-none d-md-block w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={`${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-box mt-3">
                    <div className="casino-table-left-box">
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          {reqRes?.data?.data?.t2[3]?.b1}{" "}
                        </div>

                        {reqRes?.data?.data?.t2[3]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <span className="casino-odds">
                              <span>{reqRes?.data?.data?.t2[3]?.nat} </span>
                            </span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.b1,
                                reqRes?.data?.data?.t2[3]?.nat,
                                7
                              );
                              setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                            }}>
                            <span className="casino-odds">
                              <span>{reqRes?.data?.data?.t2[3]?.nat} </span>
                            </span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 4 && (
                                <span
                                  className={`${
                                    ele.number == 4 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 4 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          {reqRes?.data?.data?.t2[4]?.b1}{" "}
                        </div>

                        {reqRes?.data?.data?.t2[4]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <span className="casino-odds">
                              <span>{reqRes?.data?.data?.t2[4]?.nat} </span>
                            </span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[4]?.b1,
                                reqRes?.data?.data?.t2[4]?.nat,
                                8
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}>
                            <span className="casino-odds">
                              <span>{reqRes?.data?.data?.t2[4]?.nat} </span>
                            </span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  className={`${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-center-box">
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          <span>{reqRes?.data?.data?.t2[5]?.b1} </span>
                        </div>

                        {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <div className="casino-odds">
                              <span className="card-icon ms-1">
                                <span className="card-red smallimage ">
                                  <img
                                    className="w-100 h-100"
                                    src={diamond}
                                    alt=""
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1">
                                <span className="card-red ">
                                  <img
                                    className="w-100 h-100"
                                    src={heart}
                                    alt=""
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[5]?.b1,
                                reqRes?.data?.data?.t2[5]?.nat,
                                9
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}>
                            <div className="casino-odds">
                              <span className="card-icon ms-1">
                                <span className="card-red smallimage ">
                                  <img
                                    className="w-100 h-100"
                                    src={diamond}
                                    alt=""
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1">
                                <span className="card-red ">
                                  <img
                                    className="w-100 h-100"
                                    src={heart}
                                    alt=""
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        )}

                        {/* </div>
                        </div> */}
                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 6 && (
                                <span
                                  className={`${
                                    ele.number == 6 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          <span>{reqRes?.data?.data?.t2[6]?.b1} </span>
                        </div>

                        {reqRes?.data?.data?.t2[6]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <div className="casino-odds">
                              <span className="card-icon ms-1">
                                <span className="card-black smallimage">
                                  <img
                                    className="w-100 h-100"
                                    src={club}
                                    alt=""
                                  />
                                </span>
                              </span>

                              <span className="card-icon ms-1">
                                <span className="card-black ">
                                  <img
                                    className="w-100 h-100"
                                    src={spade}
                                    alt=""
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[6]?.b1,
                                reqRes?.data?.data?.t2[6]?.nat,
                                10
                              );
                              setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                            }}>
                            <div className="casino-odds">
                              <div className="casino-odds">
                                <span className="card-icon ms-1">
                                  <span className="card-black smallimage">
                                    <img
                                      className="w-100 h-100"
                                      src={club}
                                      alt=""
                                    />
                                  </span>
                                </span>

                                <span className="card-icon ms-1">
                                  <span className="card-black ">
                                    <img
                                      className="w-100 h-100"
                                      src={spade}
                                      alt=""
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* </div>
                        </div> */}
                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 7 && (
                                <span
                                  className={`${
                                    ele.number == 7 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 7 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-right-box">
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          <span>{reqRes?.data?.data?.t2[20]?.b1} </span>
                        </div>

                        {reqRes?.data?.data?.t2[20]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <span className="casino-odds">
                              {reqRes?.data?.data?.t2[20]?.nat}{" "}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[20]?.b1,
                                reqRes?.data?.data?.t2[20]?.nat,
                                11
                              );
                              setBetIndex(reqRes?.data?.data?.t2[20]?.sid);
                            }}>
                            <span className="casino-odds">
                              {reqRes?.data?.data?.t2[20]?.nat}{" "}
                            </span>
                          </div>
                        )}

                        {/* </span>
                        </div> */}
                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 8 && (
                                <span
                                  className={`${
                                    ele.number == 8 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 8 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="aaa-odd-box">
                        <div className="casino-odds text-center text-black">
                          <span>{reqRes?.data?.data?.t2[21]?.b1} </span>
                        </div>

                        {reqRes?.data?.data?.t2[21]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme position-relative suspended-box">
                            <span className="casino-odds">
                              {reqRes?.data?.data?.t2[21]?.nat}{" "}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back casino-odds-box-theme position-relative"
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[21]?.b1,
                                reqRes?.data?.data?.t2[21]?.nat,
                                12
                              );
                              setBetIndex(reqRes?.data?.data?.t2[21]?.sid);
                            }}>
                            <span className="casino-odds">
                              {reqRes?.data?.data?.t2[21]?.nat}{" "}
                            </span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 9 && (
                                <span
                                  className={`${
                                    ele.number == 9 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}>
                                  {ele.number == 9 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-full-box aaacards mt-3">
                    <h4 className="w-100 text-center mb-2 text-black">
                      <b>{reqRes?.data?.data?.t2[7]?.b1}</b>
                    </h4>
                    <div className="d-flex mb-3 innerrow">
                      {reqRes?.data?.data?.t2
                        ?.slice(7, 20)
                        ?.map((item, index) => (
                          <div className="m-r-5 card-image" key={index}>
                            <div className="position-relative">
                              {item.gstatus != "ACTIVE" && (
                                <div className="suspended__2">
                                  <i className="fa fa-lock"></i>
                                </div>
                              )}
                              <img
                                className="w-100 h-100"
                                src={imageImports[index]}
                                alt={item.nat}
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(index + 1);
                                  handleModal(item.b1, item.nat, index + 13);
                                  setBetIndex(item.sid);
                                }}
                              />
                            </div>{" "}
                            <div className="ubook text-center m-t-5">
                              <b style={{ color: "white" }}>
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === index + 10 && (
                                      <span
                                        className={` ${
                                          ele.number == index + 10 &&
                                          ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}>
                                        {ele.number == index + 10 && ele.amount}
                                      </span>
                                    )
                                )}
                              </b>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* +++++++++++++++++++++++++++++++++++++++++ */}
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid == result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {item && <>{resultMapping[result.result]}</>}
                        </div>
                      );
                    })}
                  </div>

                  {/* <div className="rst-row d-flex">
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
