import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import { fetchAccountStatement } from "../../Redux/Slices/accountStatementSlice";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";

const BettingHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const userId = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.post(
        `Get_AllDownlineGames_User`,
        {
          UserId: userId,
          uid: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("AccountStateMent-->", responceRight?.data?.data[0]);
      // setTotalBet(responceRight?.data?.data1.TotalBet);
      // setTotalProfit(responceRight?.data?.data1.TotalProfit);
      // setTotalLoss(responceRight?.data?.data1.TotalLoss);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            UserId: item.UserId,
            Game: item.Event,
            BetType:
              item.Event === "Aviator"
                ? ""
                : item.Event === "Color Game"
                ? ""
                : item.BetType,
            SessionId:
              item.Event === "Aviator" || item.Event === "Color Game"
                ? item.BetType
                : item.SessionId,
            BetAmount: item.BetAmount,
            Profit: item.Profit,
            Loss: item.Loss,
            Edate: item.Edate,
            BookieId: `${item.username} (${item.remark})`,
            BookieType: "",
            EventType: (
              <>
                {" "}
                {item.EventType} <br />
                {item.Match}{" "}
              </>
            ),
            Match: item.Match,
            Lay: item.Lay,
            Back: item.Back,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "User Id", accessor: "UserId" },
      { Header: "Game", accessor: "Game" },
      // { Header: "Session Id", accessor: "SessionId" },
      { Header: "Bet Amount", accessor: "BetAmount" },
      // { Header: "Profit", accessor: "Profit" },
      // { Header: "Loss", accessor: "Loss" },
      { Header: "Date", accessor: "Edate" },
      // { Header: "Bet Type", accessor: "BetType" },
      // { Header: "Event Type", accessor: "EventType" },
      // { Header: "Match", accessor: "Match" },
      // { Header: "Lay", accessor: "Lay" },
      // { Header: "Back", accessor: "Back" },
      // { Header: "Bookie Id", accessor: "BookieId" },
    ],
  });

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Betting History</h4>

            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingHistory;
