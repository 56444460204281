import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./TeenPatti.css";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import c from "../../Media/2HH.png";
import d from "../../Media/AHH.png";
import e from "../../Media/KHH.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { io } from "socket.io-client";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { toast } from "react-toastify";
import { casinoBet } from "../../Redux/Slices/casinobetSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Modal from "./Modal";
import Table from "./Table";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import Rules_Modal from "../Rules_Modal";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
// import { resultSchedulerAPI } from "../../Redux/Slices/schedulerSlice";
import cardBlank from "../../Media/DTL2020/backcard.webp";
import { card52War } from "../../Utility/52CardImages.js";

const TeenPatti = () => {
  // console.log("games", games);

  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showresultModal, setShowresultModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [betDetails, setbetDetails] = useState({});
  // const { casinobetHistory } = useSelector((state) => state.casinobetHistory);

  const [rulesModal, setRulesModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: sessionStorage.getItem("userName"),
      }, {
        headers: {
          Authorization: token,
        },
      });
     // console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound} </div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
                setTrophy("");
                setCasinoGameResult([]);
              }}
            />
          </div>
          <div className="mb-1">
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  Player 8{trophy == 8 && <FaTrophy className="trophy-icon" />}
                </div>
                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[0]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[0].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1">
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 9</div>
                  {trophy == 9 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[1]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[1].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 !== 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 10</div>
                  {trophy == 10 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[2]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[2].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 == 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player 11</div>
                  {trophy == 11 && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex justify-content-center">
                  <div className="gamecard-div mx-1">
                    <img
                      src={casinoGameResult[3]?.image}
                      className="img-fluid"
                      alt="Card"
                    />
                  </div>
                  {casinoGameResult.filter(
                    (item) => item.col2 === casinoGameResult[3].col2
                  ).length > 1 &&
                    casinoGameResult.slice(4).map(
                      (card, index) =>
                        index % 2 !== 0 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={card?.image}
                              className="img-fluid"
                              alt="Card"
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("32cardA", parsedData[0].reqResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: sessionStorage.getItem("userName"),
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const parseCardData = (cardData) => {
    return cardData.split(",").map((card) => {
      const cardName = card.trim(); // Extract card name and trim any whitespace
      return cardName;
    });
  };

  const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  //console.log("arr", arrayData);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) :  */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div className="col-lg-10">
      <div className="row home">
        <div className="col-lg-8 p-1">
          <div className="teenpatti-game-div pt-2 p-2">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 teenpatti-h-p">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2 teenpatti-h-p">
                  Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card mt-1">
              <div className="row">
                <div className="col-10">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="timer ">
                  <div
                    className="container"
                    style={{
                      color: "white",
                      marginLeft: "-1px",
                      marginTop: "-150px",
                    }}
                  >
                    {/* <div className="timer_container position-absolute"  > */}
                    {arrayData[0] !== "1" && (
                      <>
                        <span style={{ fontSize: "15px" }}>
                          PLAYER 8:{reqRes?.data?.data?.t1[0]?.C1}
                        </span>
                        <div>
                          {[0, 4].map((key, index) => {
                            const cardId = arrayData[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId)}
                                  style={{
                                    width: "20px",
                                    height: "auto",
                                    marginLeft: "20px",
                                  }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </>
                    )}
                  <span>
                      {arrayData[1] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 9:{reqRes?.data?.data?.t1[0]?.C2}
                          </span>
                          <div>
                            {[1, 5].map((key, index) => {
                              const cardId = arrayData[key]; // Access the zeroth index of arrayData
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>
                    {/* PLAYER 10:22 */}

                    <span>
                      {arrayData[2] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 10:{reqRes?.data?.data?.t1[0]?.C3}
                          </span>
                          <div>
                            {[2, 6].map((key, index) => {
                              const cardId = arrayData[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>

                    {/*PLAYER 11:20  */}

                    <span>
                      {arrayData[3] !== "1" && (
                        <>
                          <span style={{ fontSize: "15px" }}>
                            PLAYER 11:{reqRes?.data?.data?.t1[0]?.C4}
                          </span>
                          <div>
                            {[3, 7].map((key, index) => {
                              const cardId = arrayData[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId)}
                                    style={{
                                      width: "20px",
                                      height: "auto",
                                      marginLeft: "20px",
                                    }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </div>
                        </>
                      )}
                    </span>

                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}
                >
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "40px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-primary"
                        style={{ cursor: "default" }}
                      >
                        <span> 
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <-------------------------------Back-lay-table----------> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="table-h-div row">
                  <div className="col-5 d-flex align-items-center ps-2"></div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Back
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Lay
                  </div>
                </div>
              </div>
            </div>

            {reqRes?.data?.data?.t2?.map((req, index) => (
              <div className="row" key={index}>
                <div className="col-lg-12">
                  <Table
                    key={req.sid}
                    req={req}
                    setShowModal={setShowModal}
                    betDetails={betDetails}
                    setbetDetails={setbetDetails}
                    period={reqRes?.data?.data?.t1[0].mid}
                    index={index}
                    setRealTimeData={setRealTimeData}
                  />
                </div>
              </div>
            ))}

            {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.data?.data?.map((result, index) => {
                    const item = reqRes?.data?.data?.t2.find(
                      (item) => item.sid === result?.result
                    );
                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                          setTrophy(item.nat.split(" ")[1]);
                        }}
                        className="rst-btn ms-1"
                        key={index}>
                        {item && <>{item.nat.split(" ")[1]}</>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}
            </div>
          </div>
        </div>

        <RightSidebar />

        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
            realTime={realTime}
            setRealTimeData={setRealTimeData}
          />
        )}
        {showresultModal && <ResultsModal />}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
};

export default TeenPatti;
