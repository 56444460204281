//  dd ko hh
// hh ko ss
// ss ko dd
// cc thik he
// one day poker
// 52 cardimages

import twoCC from "../Media/52Cards/2CC.jpg";
import twoDD from "../Media/52Cards/2DD.jpg";
import twoHH from "../Media/52Cards/2HH.jpg";
import twoSS from "../Media/52Cards/2SS.jpg";

import threeCC from "../Media/52Cards/3CC.jpg";
import threeDD from "../Media/52Cards/3DD.jpg";
import threeHH from "../Media/52Cards/3HH.jpg";
import threeSS from "../Media/52Cards/3SS.jpg";

import fourCC from "../Media/52Cards/4CC.jpg";
import fourDD from "../Media/52Cards/4DD.jpg";
import fourHH from "../Media/52Cards/4HH.jpg";
import fourSS from "../Media/52Cards/4SS.jpg";

import fiveCC from "../Media/52Cards/5CC.jpg";
import fiveDD from "../Media/52Cards/5DD.jpg";
import fiveHH from "../Media/52Cards/5HH.jpg";
import fiveSS from "../Media/52Cards/5SS.jpg";

import sixCC from  "../Media/52Cards/6CC.jpg";
import sixDD from  "../Media/52Cards/6DD.jpg";
import sixHH from  "../Media/52Cards/6HH.jpg";
import sixSS from  "../Media/52Cards/6SS.jpg";

import sevenCC from  "../Media/52Cards/7CC.jpg";
import sevenHH from  "../Media/52Cards/7HH.jpg";
import sevenSS from  "../Media/52Cards/7SS.jpg";
import sevenDD from  "../Media/52Cards/7DD.jpg";

import eightDD from  "../Media/52Cards/8DD.jpg";
import eightHH from  "../Media/52Cards/8HH.jpg";
import eightSS from  "../Media/52Cards/8SS.jpg";
import eightCC from  "../Media/52Cards/8CC.jpg";

import nineCC from  "../Media/52Cards/9CC.jpg";
import nineDD from  "../Media/52Cards/9DD.jpg";
import nineHH from  "../Media/52Cards/9HH.jpg";
import nineSS from  "../Media/52Cards/9SS.jpg";

import tenDD from  "../Media/52Cards/10DD.jpg";
import tenHH from  "../Media/52Cards/10HH.jpg";
import tenSS from  "../Media/52Cards/10SS.jpg";

import acc from  "../Media/52Cards/ACC.jpg";
import ahh from  "../Media/52Cards/AHH.jpg";
import add from  "../Media/52Cards/ADD.jpg";
import ass from  "../Media/52Cards/ASS.jpg";

import jhh from  "../Media/52Cards/JHH.jpg";
import jss from  "../Media/52Cards/JSS.jpg";
import jcc from  "../Media/52Cards/JCC.jpg";
import jdd from  "../Media/52Cards/JDD.jpg";

import kcc from  "../Media/52Cards/KCC.jpg";
import kdd from  "../Media/52Cards/KDD.jpg";
import khh from  "../Media/52Cards/KHH.jpg";
import kss from  "../Media/52Cards/KSS.jpg";

import qcc from  "../Media/52Cards/QCC.jpg";
import qdd from  "../Media/52Cards/QDD.jpg";
import qhh from  "../Media/52Cards/QHH.jpg";
import qss from  "../Media/52Cards/QSS.jpg";

//  dd ko hh
// hh ko ss
// ss ko dd
// cc thik he


const card52Images = [
  { id: "2CC", src: twoCC },
  { id: "2HH", src: twoDD },           
  { id: "2SS", src: twoHH },  
  { id: "2DD", src: twoSS }, 

  { id: "3CC", src: threeCC }, 
  { id: "3HH", src: threeDD },         
  { id: "3SS", src: threeHH },     
  { id: "3DD", src: threeSS }, 

  { id: "4CC", src: fourCC },         
  { id: "4HH", src: fourDD },           
  { id: "4SS", src: fourHH },
  { id: "4DD", src: fourSS },

  { id: "5HH", src: fiveDD },
  { id: "5CC", src: fiveCC },
  { id: "5SS", src: fiveHH},
  { id: "5DD", src: fiveSS },
 
  { id: "6CC", src: sixCC },
  { id: "6HH", src: sixDD },
  { id: "6SS", src: sixHH },
  { id: "6DD", src: sixSS },

  { id: "7CC", src: sevenCC },
  { id: "7SS", src: sevenHH },
  { id: "7DD", src: sevenSS },
  { id: "7HH", src: sevenDD },

  { id: "8HH", src: eightDD },
  { id: "8SS", src: eightHH },
  { id: "8DD", src: eightSS },
  { id: "8CC", src: eightCC },

  { id: "9SS", src: nineHH },
  { id: "9CC", src: nineCC },
  { id: "9HH", src: nineDD },
  { id: "9DD", src: nineSS },

  { id: "10HH", src: tenDD },
  { id: "10SS", src: tenHH },
  { id: "10DD", src: tenSS },

  { id: "ACC", src: acc },
  { id: "ASS", src: ahh },
  { id: "AHH", src: add },
  { id: "ADD", src: ass },

  { id: "JSS", src: jhh },
  { id: "JDD", src: jss },
  { id: "JCC", src: jcc },
  { id: "JHH", src: jdd },

  { id: "KCC", src: kcc },
  { id: "KHH", src: kdd },
  { id: "KSS", src: khh },
  { id: "KDD", src: kss },
  
  { id: "QCC", src: qcc },
  { id: "QHH", src: qdd },
  { id: "QSS", src: qhh },
  { id: "QDD", src: qss },
];

export default card52Images;

export const card52War = [
  { id: "2CC", src: twoCC },
  { id: "2DD", src: twoHH },           
  { id: "2HH", src: twoSS },  
  { id: "2SS", src: twoDD }, 

  { id: "3CC", src: threeCC }, 
  { id: "3DD", src: threeHH },         
  { id: "3HH", src: threeSS },     
  { id: "3SS", src: threeDD }, 

  { id: "4CC", src: fourCC },         
  { id: "4DD", src: fourHH },           
  { id: "4HH", src: fourSS },
  { id: "4SS", src: fourDD },

  { id: "5CC", src: fiveCC },
  { id: "5DD", src: fiveHH },
  { id: "5HH", src: fiveSS},
  { id: "5SS", src: fiveDD },
 
  { id: "6CC", src: sixCC },
  { id: "6DD", src: sixHH },
  { id: "6HH", src: sixSS },
  { id: "6SS", src: sixDD },

  { id: "7CC", src: sevenCC },
  { id: "7HH", src: sevenSS },
  { id: "7SS", src: sevenDD },
  { id: "7DD", src: sevenHH },

  { id: "8CC", src: eightCC },
  { id: "8DD", src: eightHH },
  { id: "8HH", src: eightSS },
  { id: "8SS", src: eightDD },

  { id: "9CC", src: nineCC },
  { id: "9DD", src: nineHH },
  { id: "9HH", src: nineSS },
  { id: "9SS", src: nineDD },

  { id: "10DD", src: tenHH },
  { id: "10HH", src: tenSS },
  { id: "10SS", src: tenDD },

  { id: "ACC", src: acc },
  { id: "ADD", src: ahh },
  { id: "AHH", src: ass },
  { id: "ASS", src: add },

  { id: "JCC", src: jcc },
  { id: "JDD", src: jhh },
  { id: "JHH", src: jss },
  { id: "JSS", src: jdd },

  { id: "KCC", src: kcc },
  { id: "KDD", src: khh },
  { id: "KHH", src: kss },
  { id: "KSS", src: kdd },
  
  { id: "QCC", src: qcc },
  { id: "QDD", src: qhh },
  { id: "QHH", src: qss },
  { id: "QSS", src: qdd },
];
  