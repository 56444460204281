import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { API } from "../../API/API";
import logo1 from "../../Media/realgame24.png";

export default function ForgetPassword() {
  const history = useNavigate();
  const [LoginId, setLoginId] = useState("");
  const dispatch = useDispatch();

  const ForgetpassToApi = async (e) => {
    e.preventDefault();
    if (LoginId.length !== 6) {
      toast.error(`Invalid Login Id or Password`);
      return;
    }

    let res = await API.post("/forgetpassword", {
      uid: LoginId,
    });
    console.log("res--->", res);

    if (
      res.data.data ===
      "Password has been successfully sent to registered email Id"
    ) {
      toast.success(res.data.data);
      setTimeout(() => {
        history("/Login");
      }, 1000);
    } else {
      toast.error(`Invalid Login Id`);
    }
  };

  return (
    <div className="background-img">
      <div className="d-flex justify-content-center align-items-center">
        <div className="login-card  mt-5">
          <div className="d-flex justify-content-center mt-4">
            <img className="img-fluid img-div" src={logo1} alt="a" />
          </div>

          <div className="px-4">
            <form onSubmit={ForgetpassToApi}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  className=" login-input p-1 px-2"
                  id="exampleInputEmail1"
                  placeholder="Enter UserName"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    setLoginId(e.target.value);
                  }}
                  maxLength={6}
                />
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn login-btn mt-2">
                  Submit
                </button>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <div className="bth d-flex justify-content-center mt-2">
                      Back to &nbsp;{" "}
                      <span>
                        <a className="nav-link" href="/">
                          {" "}
                          Home
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <div className="bth d-flex justify-content-center mt-2">
                      Login &nbsp;{" "}
                      <span>
                        <a className="nav-link" href="/Login">
                          {" "}
                          Here
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
