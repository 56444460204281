import { useDispatch, useSelector } from "react-redux";
import { placeBet } from "../../Redux/Slices/betSlice";
import { toast } from "react-toastify";
import { memo, useEffect, useState } from "react";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useParams } from "react-router-dom";
import { fetchSessionStackData } from "../../Redux/api/sessionStackAPI";
import { calculateProfit } from "../../Redux/api/calculateProfit";
import { resetProfitData } from "../../Redux/Slices/calculateProfitSlice";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { LastBetDetails } from "../../Redux/Slices/LastbetSlice";
import { fetchDashbetHistory } from "../../Redux/Slices/dashbetHistorySlice";
import { fetchBetButton } from "../../Redux/Slices/betButtonSlice";

const SportModal = ({
  setModal,
  setbetType,
  setmarketType,
  setbetDetails,
  betDetails,
  betType,
  marketType,
  countryName,
  realtimeData,
  setRealtimeData,
}) => {
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.auth.currentMatch);
  const { Profitdata } = useSelector((state) => state.userProfit);
  // const { userBalance } = useSelector((state) => state.userBalanceData);

  const { buttonData } = useSelector((state) => state.buttonData);
  const [count, setCount] = useState(0);
  useEffect(() => {
    dispatch(fetchBetButton({ id: uid, token }));
  }, [dispatch, uid]);

  const handleStake = (value) => {
    setbetDetails({ ...betDetails, stake: value, uid });
    dispatch(
      calculateProfit({
        stake: value,
        marketType,
        back:
          betType === "back"
            ? betDetails.Backprice || betDetails.BackPrice2
            : 0,
        lay:
          betType === "lay" ? betDetails.Layprice || betDetails.LayPrice2 : 0,
        token,
      })
    );
  };

  const getPrice = () => {
    if (betType === "back") {
      return betDetails?.Backprice ?? betDetails?.BackPrice2;
    }
    return betDetails?.Layprice ?? betDetails?.LayPrice2;
  };

  // console.log("sportModal", betDetails);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setModal(false);
    setCount(count + 1);
    // console.log("bet submit", realtimeData);
    try {
      if (!realtimeData) {
        toast.error("Odd Value is miss Match");
        return;
      }
      if (betDetails.stake <= 0) {
        toast.error("Invalid Stake");
        return;
      }

      if (marketType === "Match Odds" && getPrice() > 10) {
        toast.error("Invalid Bet. Wait Some Time");
        return null;
      }

      let responce = await dispatch(
        placeBet({ betDetails, marketType, token })
      ).unwrap();
      if (responce.data === "Bet Placed.") {
        toast.success(responce.data);
        if (marketType === "Session Market") {
          dispatch(
            fetchSessionStackData({
              userId: uid,
              id: id,
              mname: countryName,
            })
          );
        } else {
          dispatch(
            fetchbetStackData({
              userId: uid,
              mId: id,
              matchType: marketType,
              token,
            })
          );
        }

        if (marketType == "Match Odds") {
          dispatch(LastBetDetails({ userId: uid, id: id, token }));
        }
        // dispatch(fetchuserBalance({ id: uid, token }));

        dispatch(resetProfitData());
        dispatch(fetchDashbetHistory({ userId: uid, token, id }));
        window.location.reload();
      } else {
        toast.error(responce.data);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setRealtimeData(false);
      setbetType(null);
      setmarketType(null);
      setbetDetails({});
    }
  };


  return (
    <>
      <div
        onClick={() => {
          setModal(false);
          setbetType(null);
          setmarketType(null);
          setbetDetails({});
        }}
        className="data-modal-wrapper"></div>
      <div className="data-modal-container">
        <div
          className={`${
            betType === "back" ? "head-border-blue" : "head-border"
          } d-flex align-items-center`}>
          <p className="my-auto mx-2">{marketType}</p>
        </div>
        <div className="container-body">
          {/* <p className="my-1 mx-2">Player 9</p> */}
          {/* <div className="row">
            <div className="col-4">
              <p className="mx-2 ">PTS :</p>
            </div>
            <div className="col-2">
              <div className="">
                {parseFloat(userBalance.wallet_amount || 0).toFixed(2)}
              </div>
            </div>
            <div className="col-2">
              <div className="mx-2">Exp :</div>
            </div>
            <div className="col-4">
              <p className="mx-3 ">
                {parseFloat(userBalance.exp_amount || 0).toFixed(2)}
              </p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Bet For :</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn">
                {betDetails.runnerName || betDetails.team}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Odds</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn"> {getPrice()}</button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Stake</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn"> {betDetails.stake}</button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Profit</p>
            </div>
            <div className="col-8">
              <button className="modal-g-btn">
                {parseFloat(Profitdata?.odds || 0).toFixed(2)}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Liability</p>
            </div>
            <div className="col-8">
              <button className="modal-r-btn">
                {parseFloat(Profitdata?.Stake || 0).toFixed(2)}
              </button>
            </div>
          </div>
          <div className="orange-buttons-row d-flex flex-wrap">
            {buttonData.map((item, index) => (
              <button
                className="orb mx-1 my-1"
                key={index}
                onClick={() => {
                  handleStake(item.price_value);
                }}>
                {item.price_level}
              </button>
            ))}
          </div>
        </div>
        <div className="modal-footer row mt-1">
          <div className="col-5"></div>
          <div className="col-7 py-2">
            <button
              onClick={() => {
                setModal(false);
                setbetType(null);
                setmarketType(null);
                setbetDetails({});
                dispatch(resetProfitData());
              }}
              className="mcb ">
              Cancel
            </button>
            <button
              className="mbb mx-1"
              onClick={handleSubmit}
              disabled={count > 0 ? true : false}>
              Place Bet
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SportModal);
