import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

// import card52Images from "../../Utility/52CardImages.js";
// import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function TeenPatti1day() {
  const { id } = useParams();
  const uid = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  const dispatch = useDispatch();
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("poker socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        // console.log("parsed_result name", parsedData[0].reqResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  
  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  Player A{" "}
                  {trophy == "A" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex">
                  {casinoGameResult &&
                    casinoGameResult.map(
                      (item, index) =>
                        index < 3 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={item.image}
                              className="img-fluid"
                              alt={`Player B Card ${index}`}
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  {trophy == "B" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex">
                  {casinoGameResult &&
                    casinoGameResult.map(
                      (item, index) =>
                        index > 2 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={item.image}
                              className="img-fluid"
                              alt={`Player B Card ${index}`}
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t1.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t1, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };


  // const findCardImage = (cardId) => {
  //   const card = card52Images.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time:{reqRes?.data?.data?.t1[0]?.lasttime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{ color: "white",marginLeft:"-1px",  marginTop:'-150px'}}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span>Player A</span> 
                      <div>
                        {["C1","C2","C3"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>   
                          ) : null;
                        })}
                      </div>
                      <span>
                      <span>Player B</span>
                      <div>
                        {["C1","C2","C3"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[1]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span> 
                      {/* Board */}

                      {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}


                     

                      {/* </div> */}
                    </div>
                  </div>
                  
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                      <div
                        className="timer_container position-absolute"
                        style={{ right: "40px", bottom: "15px" }}>
                        <button
                          class="btn btn-primary"
                          style={{ cursor: "default" }}>
                          <span>
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.lasttime || 0}{" "}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* <-------------------------------Back-lay-table----------> */}
              {/* old design */}
              <div>
                <div className="table-h-div row">
                  <div className="col-6 d-flex align-items-center ps-2"></div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Back
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Lay
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    {reqRes?.data?.data?.t1[0]?.nat}{" "}
                    <span className="success-o ms-1">
                      {" "}
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 1 && (
                            <span
                              className={`${
                                ele.number == 1 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}>
                              {ele.number == 1 && ele.amount}
                            </span>
                          )
                      )}
                    </span>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    {reqRes?.data?.data?.t1[0]?.gstatus == "SUSPENDED" ||
                    reqRes?.data?.data?.t1[0]?.gstatus == "CLOSED" ? (
                      <button className="back-btn">
                        {reqRes?.data?.data?.t1[0]?.gstatus}
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t1[0]?.b1,
                            reqRes?.data?.data?.t1[0]?.nat,
                            1
                          );
                          setBetIndex(reqRes?.data?.data?.t1[0]?.sid);
                        }}>
                        {reqRes?.data?.data?.t1[0]?.b1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    {reqRes?.data?.data?.t1[0]?.gstatus == "SUSPENDED" ||
                    reqRes?.data?.data?.t1[0]?.gstatus == "CLOSED" ? (
                      <button className="lay-btn">
                        {reqRes?.data?.data?.t1[0]?.gstatus}
                      </button>
                    ) : (
                      <button
                        className="lay-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t1[0]?.l1,
                            reqRes?.data?.data?.t1[0]?.nat,
                            3
                          );
                          setBetIndex(reqRes?.data?.data?.t1[0]?.sid);
                        }}>
                        {reqRes?.data?.data?.t1[0]?.l1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    {reqRes?.data?.data?.t1[1]?.nat}{" "}
                    <span className="success-o ms-1">
                      {" "}
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 2 && (
                            <span
                              className={`${
                                ele.number == 2 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}>
                              {ele.number == 2 && ele.amount}
                            </span>
                          )
                      )}
                    </span>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    {reqRes?.data?.data?.t1[1]?.gstatus == "SUSPENDED" ||
                    reqRes?.data?.data?.t1[1]?.gstatus == "CLOSED" ? (
                      <button className="back-btn">
                        {reqRes?.data?.data?.t1[1]?.gstatus}
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t1[1]?.b1,
                            reqRes?.data?.data?.t1[1]?.nat,
                            2
                          );
                          setBetIndex(reqRes?.data?.data?.t1[1]?.sid);
                        }}>
                        {reqRes?.data?.data?.t1[1]?.b1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    {reqRes?.data?.data?.t1[1]?.gstatus == "SUSPENDED" ||
                    reqRes?.data?.data?.t1[1]?.gstatus == "CLOSED" ? (
                      <button className="lay-btn">
                        {reqRes?.data?.data?.t1[1]?.gstatus}
                      </button>
                    ) : (
                      <button
                        className="lay-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t1[1]?.l1,
                            reqRes?.data?.data?.t1[1]?.nat,
                            4
                          );
                          setBetIndex(reqRes?.data?.data?.t1[1]?.sid);
                        }}>
                        {reqRes?.data?.data?.t1[1]?.l1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* new design */}
              {/* <div>
                <div className="table-h-div row casino-table-box">
                  <div className="col-md-6 casino-table-left-box">
                    <div className="row">
                      <div className="col-6 d-flex align-items-center pt-1 ps-2">
                        Player A
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        Back
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        Lay
                      </div>
                    </div>
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        PLAYER A <span className="success-o ms-1"> 0</span>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                          }}
                          className="back-btn">
                          Suspended
                        </button>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        <button className="lay-btn">Suspended</button>
                      </div>
                    </div>
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        PLAYER A <span className="success-o ms-1"> 0</span>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2 suspended-box ">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                          }}
                          className="back-btn">
                          Suspended
                        </button>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center suspended-box ">
                        <button className="lay-btn">Suspended</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 casino-table-left-box">
                    <div className="row ">
                      <div className="col-6 d-flex align-items-center pt-1 ps-2">
                        Player A
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        Back
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        Lay
                      </div>
                    </div>
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        PLAYER A <span className="success-o ms-1"> 0</span>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2 suspended-box ">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                          }}
                          className="back-btn">
                          Suspended
                        </button>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        <button className="lay-btn">Suspended</button>
                      </div>
                    </div>
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        PLAYER A <span className="success-o ms-1"> 0</span>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                          }}
                          className="back-btn">
                          Suspended
                        </button>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-center suspended-box">
                        <button className="lay-btn">Suspended</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="casino-table-full-box teenpatti1day-other-odds mt-3">
                  <div className="casino-table-header">
                    <div className="casino-nation-detail" />
                    <div className="casino-odds-box">Card 1</div>
                    <div className="casino-odds-box">Card 2</div>
                    <div className="casino-odds-box">Card 3</div>
                    <div className="casino-odds-box">Card 4</div>
                    <div className="casino-odds-box">Card 5</div>
                    <div className="casino-odds-box">Card 6</div>
                  </div>
                  <div className="casino-table-body">
                    <div className="casino-table-row">
                      <div className="casino-nation-detail">
                        <div className="casino-nation-name">Odd</div>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back">
                        <span className="casino-odds">1.82</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                    </div>
                    <div className="casino-table-row">
                      <div className="casino-nation-detail">
                        <div className="casino-nation-name">Even</div>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                      <div className="casino-odds-box back">
                        <span className="casino-odds">2.15</span>
                      </div>
                      <div className="casino-odds-box back suspended-box">
                        <span className="casino-odds">0</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t1.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[item.nat.length - 1]);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {item && <>{item.nat[item.nat.length - 1]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
        </div>
      </div>
    </>
  );
}
