import React, { useEffect, useState } from "react";
import "./Mlmdashboard.css";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import picon from "../../Media/MLM dashB/user-profile.png";
import a from "../../Media/MLM dashB/2.png";
import b from "../../Media/MLM dashB/5.png";
import c from "../../Media/MLM dashB/8.png";
import d from "../../Media/MLM dashB/totalw.png";
import logo from "../../Media/MLM dashB/logo.png";
import withdrawal from "../../Media/MLM dashB/withdrawal.png";
import gamewallet from "../../Media/MLM dashB/gamewallet.png";
import direct from "../../Media/MLM dashB/direct.png";
import totalteam from "../../Media/MLM dashB/totalteam.png";
import teambusiness from "../../Media/MLM dashB/teambusiness.png";
import deposit from "../../Media/MLM dashB/deposit.png";
import recharge from "../../Media/MLM dashB/recharge.png";
import level from "../../Media/MLM dashB/level.png";
import dailysalary from "../../Media/MLM dashB/dailysalary.png";
import win from "../../Media/MLM dashB/win.png";
import bonus from "../../Media/MLM dashB/bonus.png";
import leadership from "../../Media/MLM dashB/leadership.png";
import rank from "../../Media/MLM dashB/rank.png";
import leaderincome from "../../Media/MLM dashB/leaderincome.png";
import totalincome from "../../Media/MLM dashB/totalincome.png";
import totalwithdrawal from "../../Media/MLM dashB/totalwithdrawal.png";
import fund from "../../Media/MLM dashB/fund.png";
import net from "../../Media/MLM dashB/net.png";

import { toast } from "react-toastify";

export default function Mlmdashboard() {
  let uId = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dashboard, setdashboard] = useState("");

  useEffect(() => {
    const DashboardValues = async () => {
      try {
        let resnetbal = await API.post(
          `/getbetawallet`,
          { username: uId },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("mlmDash", resnetbal.data.data[0][0]);
        setdashboard(resnetbal.data.data[0][0]);
      } catch (e) {
        console.log("Error While Fatch Dashboard API", e);
        if (
          e.response.data.showableMessage ===
          "Please login to access this resource"
        ) {
          sessionStorage.clear();
          dispatch(logoutUser());
          navigate("/");
        }
      }
    };

    DashboardValues();
  }, []);

  let URL = window.location.origin;
  const CopyReferralLink = () => {
    const text = `${URL}/registration?userid=${uId}`;
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  return (
    <div className="col-lg-10 ">
      <div className="row ">
        <div className="col-lg-8">
          <div className="container-fluid">
            <div className="row leftsidecontent ">
              <div className="col-xl-6 col-md-6 col-12">
                <div className="card border-0 topcard profilcard h-100">
                  <div className="card-body">
                    <div className="card_profileimg mb-3">
                      <img
                        className="w-100 h-100 rounded-pill"
                        src={picon}
                        alt="profile"
                      />
                    </div>
                    <div className="userid">User Id: {dashboard.uid}</div>
                    <div className="wallet ">Affiliate Link</div>
                    <p
                      className="text-center copytext border-theme1"
                      onClick={CopyReferralLink}
                      id="walletAddress"
                    >
                      Copy Referral Link
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-12 ">
                <div className="card topcard border-0 h-100 p-3">
                  <div className="row mx-0 h-100">
                    <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                      <div className="totoinvestment">
                        <img
                          className="w-100 h-100 rounded-pill"
                          src={logo}
                          alt="Total_Investment"
                        />
                      </div>
                      <div className="heading">Total PTS</div>
                      <div className="total fw-bold text-white text-center">
                        {dashboard.MainWalletBal}
                      </div>
                    </div>
                    <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                      <div className="totoinvestment">
                        <img
                          className="w-100 h-100 rounded-pill"
                          src={logo}
                          alt="Kick_Investment"
                        />
                      </div>
                      <div className="heading">Exposure</div>
                      <div className="total fw-bold text-white text-center">
                        {dashboard.Exposure}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mx-0 my-3 samllcardsection">
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={gamewallet}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Direct Team</div>
                          <div className="sub_heading">
                            {dashboard.DirectTeam}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={withdrawal}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Team</div>
                          <div className="sub_heading">
                            {dashboard.TotalTeam}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/DirectIncomeStaking"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={a}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Self Deposit</div>
                          <div className="sub_heading">
                            {dashboard.SelfDeposit}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/StakingLevelBonus"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={b}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Self Betting</div>
                          <div className="sub_heading">
                            {dashboard.SelfBetting}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/MatrixDownline"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={c}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Team Deposit</div>
                          <div className="sub_heading">
                            {dashboard.TeamDeposit}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/stakingGlobalteam"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={d}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Team Betting</div>
                          <div className="sub_heading">
                            {dashboard.TeamBetting}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/ReturnIncome"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={direct}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                            Direct Deposit Income
                          </div>
                          <div className="sub_heading">
                            {dashboard.DirectDepositIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                {/* <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={totalteam}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                            Direct Betting Income
                          </div>
                          <div className="sub_heading">
                            {dashboard?.DirectBettingIcome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/MatrixIncome"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={teambusiness}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Level Income</div>
                          <div className="sub_heading">
                            {dashboard.LevelIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/MatrixIncome"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={teambusiness}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Recharge Package Profit</div>
                          <div className="sub_heading">
                            {dashboard.HoldingBonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={deposit}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Salary Bonus</div>
                          <div className="sub_heading">
                            {dashboard.RankSalaryIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={dailysalary}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Rank Bonus</div>
                          <div className="sub_heading">
                            {dashboard.DiiferentialIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={recharge}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Level Difference Bonus</div>
                          <div className="sub_heading">
                            {dashboard.RecruiterBonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={recharge}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Jackpot Bonus</div>
                          <div className="sub_heading">
                            {dashboard.JackpotBonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={level}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Transfer Bonus</div>
                          <div className="sub_heading">
                            {dashboard.ConvertBonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={dailysalary}
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Winning Bonus</div>
                          <div className="sub_heading">
                            {dashboard.Wining_Bonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                {/* <div className=" col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={rank}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Loss Trade Bonus</div>
                          <div className="sub_heading">
                            {dashboard.LossTradeBonus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={leaderincome}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Franchisee Wallet</div>
                          <div className="sub_heading">
                            {dashboard.FranchiseeWalletBal}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={win}
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Income</div>
                          <div className="sub_heading">
                            {dashboard.NetIncome}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawalhistory"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={bonus}
                              alt="totalw"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Withdrawal</div>
                          <div className="sub_heading">
                            {dashboard.WitdrawalAmount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/RewardIncome"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={leadership}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                            Withdrawal-Game Wallet
                          </div>
                          <div className="sub_heading">
                            {dashboard.ConvertWallet}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className=" col-12 col-sm-6 col-md-4">
                  {/* <Link to="/RewardIncome"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src={leadership}
                              alt="TodayBusiness"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                            Net Withdrawal Balance
                          </div>
                          <div className="sub_heading">
                            {dashboard.WithdrawalWalletBal}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  );
}
