import React, { useEffect, useState } from "react";
import { fetchUserdepositHistoryData } from "../../Redux/Slices/depositHistorySlice";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";

export default function DepositPaymentHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      let responceRight = await API?.post(
        `bankFundRequestHistory`,
        {
          uid: userId,
        },

        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("LeaderShip-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            uid: item.uid,
            user_id: item.uid,
            accountnumber: item.accountnumber,
            amount: item.amount,
            deduct: item.deduct,
            utrno: item.utrno,
            requestedDate: item.requestedDate,
            st:
              item.st === "Approve" ? (
                <span style={{ color: "green" }}>Approved</span>
              ) : item.st === "Pending" ? (
                <span style={{ color: "red" }}>Pending</span>
              ) : (
                <span style={{ color: "orange" }}>{item.st}</span>
              ),
            approveDate: item.approveDate,
            cancelDate: item.cancelDate,
            remark: item.remark,
            bankname: item.bankname,
            ifsc: item.ifsc,
            holder: item.holder,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      //{ Header: "User Id", accessor: "user_id" },
      { Header: "Requested Amount", accessor: "amount" },
      { Header: "UTR No.", accessor: "utrno" },
      { Header: "Requested Date", accessor: "requestedDate" },
      { Header: "Status", accessor: "st" },
      { Header: "Approved Date", accessor: "approveDate" },
      { Header: "Cancel Date", accessor: "cancelDate" },
      { Header: "Account Number", accessor: "accountnumber" },
      { Header: "Bank Name", accessor: "bankname" },
      { Header: "IFSC", accessor: "ifsc" },
      { Header: "Holder", accessor: "holder" },
    ],
  });
  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Deposit INR History</h4>

            <Table data={[...currentPost]} columns={matching_income.cols} />
            <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={leftreferralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
