import React, { useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./ThirtyTwoCards.css";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useState } from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RulesModal from "../Rules_Modal";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

const ThirtyTwoCards = () => {
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [realTime, setRealTimeData] = useState(false);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  // console.log("stack_3patti", thirtyTwoCardStack);

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  async function Mainwallet_API() {
    try {
      let res = await API.post("/manage_mainwallet_teenpetti", {
        priodid: results[0]?.mid,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("mainWallet", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      // console.log("filter", betDetails,filteredData);
      reqRes?.data?.data?.t2[2]?.gstatus != 0 &&
        betDetails?.nat === filteredData[0]?.nat &&
        betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);
  const [history, setHistory] = useState([]);

  async function ManageMainwallet_API(periodPts) {
    // console.log("periodPts==>", periodPts);
    try {
      // let res = await API.post("/Casino_manage_mainwallet", {
      //   priodid: periodPts,
      // });

      // console.log("mainwall res", res.data.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
      // console.log("data====", data);
      if (data[0][0] !== undefined) {
        if (data[0][0].remark === "Win" || data[0][0].remark === "Loss") {
          ManageMainwallet_API(data[0][0].period);
        }
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (reqRes?.data?.data?.t1[0]?.mid != 0) {
      ResultScheduler_API();
      dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
      const timeId = setTimeout(() => {
        Mainwallet_API();
      }, 2000);

      return () => clearTimeout(timeId);
    }
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) :  */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  // console.log("realTimeData", realTime);
  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 !== 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="col-lg-10 ">
      <div className="row home">
        <div className="col-lg-8 p-1">
          <div className="teenpatti-game-div pt-2 p-2">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name} </p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>

            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="timer ">
                  <div
                    className="container"
                    style={{
                      color: "white",
                      marginLeft: "-1px",
                      marginTop: "-150px",
                    }}
                  >
                    {/* <div className="timer_container position-absolute"  > */}
                    <span>Player A</span>
                    <div>
                      {["C1", "C2", "C3"].map((key, index) => {
                        const cardId = reqRes?.data?.data?.t1[0]?.[key];
                        return cardId ? (
                          <span key={index}>
                            <img
                              src={findCardImage(cardId) || cardBlank}
                              alt={cardId}
                              style={{
                                width: "30px",
                                height: "auto",
                                marginRight: "4px",
                              }}
                            />
                          </span>
                        ) : null;
                      })}
                    </div>
                    <span>
                      <span>Player B</span>
                      <div>
                        {["C4", "C5", "C6"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{
                                  width: "30px",
                                  height: "auto",
                                  marginRight: "4px",
                                }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}>
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "40px", bottom: "15px" }}>
                      <button
                        class="btn btn-primary"
                        style={{ cursor: "default" }}>
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <-------------------------------Back-lay-table----------> */}
            <div>
              <div className="table-h-div row">
                <div className="col-5 d-flex align-items-center ps-2"></div>
                <div className="col-2 d-flex align-items-center justify-content-center px-2">
                  Back
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center "></div>
              </div>

              <div className="card-table-inner-row row">
                <div className="col-5 d-flex align-items-center ps-2">
                  {reqRes?.data?.data?.t2[0]?.nat}{" "}
                  {/* <span className="success-o ms-1"> 0</span> */}
                </div>

                <div className="col-2 d-flex align-items-center justify-content-center px-2 position-relative">
                  {reqRes?.data?.data?.t2[0]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}

                  <button
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(1);
                      handleModal(
                        reqRes?.data?.data?.t2[0]?.rate,
                        reqRes?.data?.data?.t2[0]?.nat,
                        1
                      );
                    }}
                    className="back-btn">
                    {reqRes?.data?.data?.t2[0]?.rate}
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele, index) =>
                          ele.number === 1 && (
                            <span
                              className={`  ${ele.number == 1 && ele.amount >= 0
                                ? "success-1"
                                : "zero"
                                }`}
                              key={index}>
                              {ele.number == 1 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                  {/* )} */}
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center position-relative">
                  {reqRes?.data?.data?.t2[0]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    className="back_plus"
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(2);
                      handleModal(
                        reqRes?.data?.data?.t2[1]?.rate,
                        reqRes?.data?.data?.t2[1]?.nat,
                        2
                      );
                    }}>
                    {reqRes?.data?.data?.t2[1]?.nat} (
                    {reqRes?.data?.data?.t2[1]?.rate})
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 2 && (
                            <span
                              className={`  ${ele.number == 2 && ele.amount >= 0
                                ? "success-1"
                                : "zero"
                                }`}>
                              {ele.number == 2 && ele.amount}
                            </span>
                          )
                      )}{" "}
                    </p>
                  </button>
                </div>
              </div>
              <div className="card-table-inner-row row">
                <div className="col-5 d-flex align-items-center ps-2">
                  {reqRes?.data?.data?.t2[2]?.nat}{" "}
                  {/* <span className="success-o ms-1"> 0</span> */}
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center px-2 position-relative">
                  {reqRes?.data?.data?.t2[2]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(3);
                      handleModal(
                        reqRes?.data?.data?.t2[2]?.rate,
                        reqRes?.data?.data?.t2[2]?.nat,
                        3
                      );
                    }}
                    className="back-btn">
                    {reqRes?.data?.data?.t2[2]?.rate}
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 3 && (
                            <span
                              className={` ${ele.number == 3 && ele.amount >= 0
                                ? "success-1"
                                : "zero"
                                }`}>
                              {ele.number == 3 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center position-relative">
                  {reqRes?.data?.data?.t2[2]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    className="back_plus"
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(4);
                      handleModal(
                        reqRes?.data?.data?.t2[3]?.rate,
                        reqRes?.data?.data?.t2[3]?.nat,
                        4
                      );
                    }}>
                    {reqRes?.data?.data?.t2[3]?.nat} (
                    {reqRes?.data?.data?.t2[3]?.rate})
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 4 && (
                            <span
                              className={` ${ele.number == 4 && ele.amount >= 0
                                ? "success-1"
                                : "zero"
                                }`}>
                              {ele.number == 4 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.data?.data?.map((result, index) => {
                    const item = reqRes?.data?.data?.t2.find(
                      (item) => item.sid === result?.result
                    );

                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                        }}
                        className="rst-btn ms-1"
                        key={index}>
                        {item && <>{item?.nat[item?.nat?.length - 1]}</>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}
            </div>
          </div>
        </div>

        <RightSidebar />
        {showresultModal && <ResultsModal />}
        {/* {showDataModal && <DataModal />} */}
        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
            realTime={realTime}
            setRealTimeData={setRealTimeData}
          />
        )}
        {rulesModal && <RulesModal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
};

export default ThirtyTwoCards;
