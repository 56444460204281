import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";
import DOMPurify from "dompurify";

export default function BankDetails() {
  const token = sessionStorage.getItem("token");

  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();

  let userName = sessionStorage.getItem("userName");
  const navigate = useNavigate();

  const [details, setDetails] = useState({});
  const [WalletAddress, setWalletAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [bank_name, setbank_name] = useState("");
  const [branch, setbranch] = useState("");
  const [ifsc, setifsc] = useState("");
  const [holder, setholder] = useState("");
  const [accountnumber, setaccountnumber] = useState("");

  const GetBankDetails = async () => {
    try {
      let res = await API.get(`Userprofile_Details?uid=${userId}`, {
        headers: {
          Authorization: token,
        },
      });
      res = res.data.data[0];
      console.log("res-->", res);
      setbank_name(res.bank_name);
      setbranch(res.branch);
      setifsc(res.ifsc);
      setholder(res.holder);
      setaccountnumber(res.accountnumber);

      console.log("Profile+>", res);
      setDetails(res);
    } catch (e) {
      console.log("Somthing error in Profile API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    GetBankDetails();
  }, []);

  // console.log("123",details)

  const BankDetails_Update = async () => {
    try {
      let res = await API.post(
        "UpdateUserBankDetails",
        {
          uid: userId,
          bank_name: bank_name,
          branch: branch,
          ifsc: ifsc,
          holder: holder,
          accountnumber: accountnumber,
          Password: Password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("UpdateUserBankDetails", res.data.data.Result);

      if (res.data.data.Result === "Bank Details Update Successfull") {
        toast.success(res.data.data.Result);
      } else {
        toast.error(res.data.data.Result);
      }
    } catch (e) {
      console.log("Somthig error in Bank details Update api ", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 p-1 px-2">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Bank Details</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card cp-card mt-4 m-2 py-2 col-lg-6">
                <div className="px-4 mb-3">
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">User Id</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="User Id"
                      value={userId}
                      readOnly
                    />
                  </div>

                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Bank Name</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Bank Name"
                      value={bank_name}
                      onChange={(e) =>
                        setbank_name(DOMPurify.sanitize(e.target.value))
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">Branch Name</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Branch Name"
                      value={branch}
                      onChange={(e) =>
                        setbranch(DOMPurify.sanitize(e.target.value))
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">IFSC Code</label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="IFSC Code"
                      value={ifsc}
                      onChange={(e) =>
                        setifsc(DOMPurify.sanitize(e.target.value))
                      }
                      maxLength={20}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">
                      Account Holder Name
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder=" Account Holder Name"
                      value={holder}
                      onChange={(e) =>
                        setholder(
                          DOMPurify.sanitize(
                            e.target.value.replace(/[^a-zA-Z ]/g, "")
                          )
                        )
                      }
                      maxLength={50}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">
                      Account Number
                    </label>
                    <div style={{ display: "flex" }}>
                      <input
                        className="phone form-control"
                        type="text"
                        placeholder="Account Number"
                        value={accountnumber}
                        onChange={(e) =>
                          setaccountnumber(
                            DOMPurify.sanitize(
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          )
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="form-group mt-2">
                    <label htmlFor="exampleInputPassword1">
                      Transaction Password
                    </label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="exampleInputPassword1"
                      placeholder="Transaction Password"
                      value={Password}
                      maxLength={40}
                      onChange={(e) =>
                        setPassword(DOMPurify.sanitize(e.target.value))
                      }
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn transfer-btn mt-4"
                    onClick={() => BankDetails_Update()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
