import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import c from "../../Media/2HH.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { io } from "socket.io-client";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import Rules_Modal from "../Rules_Modal";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
import { FaTrophy } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
// import a from "../../Media/10SS.png";
// import b from "../../Media/3SS.png";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

function DragonTiger2() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];

  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [showresultModal, setShowresultModal] = useState(false);
  // const [showDataModal, setShowdataModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  // const [betno, setBetNum] = useState(0);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");

  // const { casinobetHistory, loading } = useSelector(
  //   (state) => state.casinobetHistory
  // );
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  // console.log("stack_dragon", results);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div className="mb-2">
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  Dragon
                  {trophy == "D" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex test-center">
                  {casinoGameResult[0] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[0].id}>
                      <img
                        src={casinoGameResult[0].image}
                        className="img-fluid"
                        alt={`Player A Card 0`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Tiger</div>
                  {trophy == "T" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex">
                  {casinoGameResult[1] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[1].id}>
                      <img
                        src={casinoGameResult[1].image}
                        className="img-fluid"
                        alt={`Player B Card 1`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      //console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: item.resultUrlResponse
            ? JSON.parse(item.resultUrlResponse)
            : null,
        }));

        // console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter",filteredData,betDetails)
      filteredData[0]?.gstatus !== "0" &&
      betDetails.nat === filteredData[0].nat &&
      betDetails.multipler === filteredData[0].rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };
  return (
    <div className="col-lg-10 home">
      <div className="row">
        <div className="col-lg-8 px-2 mt-1">
          <div className="hei">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10 ">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="timer ">
                  <div
                    className="container"
                    style={{
                      color: "white",
                      marginLeft: "-1px",
                      marginTop: "-150px",
                    }}
                  >
                    {/* <div className="timer_container position-absolute"  > */}
                    <span></span>
                    <div>
                      {["C1", "C2"].map((key, index) => {
                        const cardId = reqRes?.data?.data?.t1[0]?.[key];
                        return cardId ? (
                          <span key={index}>
                            <img
                              src={findCardImage(cardId) || cardBlank}
                              alt={cardId}
                              style={{
                                width: "30px",
                                height: "auto",
                                marginLeft: "5px",
                              }}
                            />
                          </span>
                        ) : null;
                      })}
                    </div>
                    <span>
                      <span></span>
                      {/* <div>
                        {["C5", "C6"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div> */}
                    </span>
                    {/* Board */}

                    {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}

                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}>
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "40px", bottom: "15px" }}>
                      <button
                        class="btn btn-primary"
                        style={{ cursor: "default" }}>
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="te">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="d-t-box m-b-10 m-t-10">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-5 text-center textss">
                              <b> {reqRes?.data?.data?.t2[0]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(1);
                                      handleModal(
                                        reqRes?.data?.data?.t2[0]?.rate,
                                        reqRes?.data?.data?.t2[0]?.nat,
                                        1
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[0]?.sid
                                      );
                                    }}>
                                    <b> {reqRes?.data?.data?.t2[0]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 1 && (
                                        <span
                                          className={`  ${
                                            ele.number == 1 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}>
                                          {ele.number == 1 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            </div>{" "}
                            <div className="col-md-2 text-center textss">
                              <b>{reqRes?.data?.data?.t2[2]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.data?.data?.t2[2]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(3);
                                      handleModal(
                                        reqRes?.data?.data?.t2[2]?.rate,
                                        reqRes?.data?.data?.t2[2]?.nat,
                                        3
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[2]?.sid
                                      );
                                    }}>
                                    <b>{reqRes?.data?.data?.t2[2]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 3 && (
                                        <span
                                          className={`  ${
                                            ele.number == 3 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}>
                                          {ele.number == 3 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            </div>{" "}
                            <div className="col-md-5 text-center textss">
                              <b>{reqRes?.data?.data?.t2[1]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(2);
                                      handleModal(
                                        reqRes?.data?.data?.t2[1]?.rate,
                                        reqRes?.data?.data?.t2[1]?.nat,
                                        2
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[1]?.sid
                                      );
                                    }}>
                                    <b>{reqRes?.data?.data?.t2[1]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 2 && (
                                        <span
                                          className={`  ${
                                            ele.number == 2 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}>
                                          {ele.number == 2 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-md-3 pair-block text-center textss">
                          <b>{reqRes?.data?.data?.t2[3]?.rate}</b>{" "}
                          <div className="m-t-10">
                            {reqRes?.data?.data?.t2[3]?.gstatus == 0 ? (
                              <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                <i className="fa fa-lock"></i>
                              </button>
                            ) : (
                              <button
                                className="text-uppercase btn-theme low-high-btn suspended textss"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(4);

                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.rate,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    4
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}>
                                <b>{reqRes?.data?.data?.t2[3]?.nat}</b>
                              </button>
                            )}
                            <span
                              className="d-block m-t-5"
                              style={{ color: "white" }}>
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 4 && (
                                    <span
                                      className={`  ${
                                        ele.number == 4 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}>
                                      {ele.number == 4 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          </div>
                        </div>{" "}
                        <div className="col-md-12 m-t-10 text-right textss">
                          <span className="m-r-10">
                            <b>Min: </b>
                            <span>{reqRes?.data?.data?.t2[0]?.min}</span>
                          </span>{" "}
                          <span>
                            <b>Max: </b>
                            <span>{reqRes?.data?.data?.t2[0]?.max}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div className="row row5">
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.data?.data?.t2[4]?.nat.split(" ")[0]}
                              </h4>
                            </div>{" "}
                            {reqRes?.data?.data?.t2
                              ?.slice(4, 8)
                              ?.map((item, index) => (
                                <div
                                  className="col-md-6 text-center textss"
                                  key={index}>
                                  <b>{item.rate}</b>{" "}
                                  <div className="m-t-10">
                                    {item.gstatus == 0 ? (
                                      <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                        <i className="fa fa-lock"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="text-uppercase btn-theme low-high-btn suspended textss"
                                        onClick={() => {
                                          // setShowModal(true);
                                          // setBetNum(index + 1);
                                          handleModal(
                                            item.rate,
                                            item.nat,
                                            index + 5
                                          );
                                          setBetIndex(item.sid);
                                        }}>
                                        <b> {item.nat.split(" ")[1]}</b>
                                      </button>
                                    )}
                                    <span
                                      className="d-block m-t-5"
                                      style={{ color: "white" }}>
                                      {thirtyTwoCardStack.map(
                                        (ele) =>
                                          ele.number === index + 5 && (
                                            <span
                                              className={`  ${
                                                ele.number == index + 5 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}>
                                              {ele.number == index + 5 &&
                                                ele.amount}
                                            </span>
                                          )
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b>Min: </b>
                                <span>{reqRes?.data?.data?.t2[4]?.min}</span>
                              </span>{" "}
                              <span>
                                <b>Max: </b>
                                <span>{reqRes?.data?.data?.t2[4]?.max}</span>
                              </span>
                            </div>{" "}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.data?.data?.t2[21]?.nat.split(" ")[0]}
                              </h4>
                            </div>{" "}
                            {reqRes?.data?.data?.t2
                              ?.slice(21, 25)
                              ?.map((item, index) => (
                                <div
                                  className="col-md-6 text-center textss"
                                  key={index}>
                                  <b>{item.rate}</b>{" "}
                                  <div className="m-t-10">
                                    {item.gstatus == 0 ? (
                                      <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                        <i className="fa fa-lock"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="text-uppercase btn-theme low-high-btn suspended textss"
                                        onClick={() => {
                                          // setShowModal(true);
                                          // setBetNum(index + 1);

                                          handleModal(
                                            item.rate,
                                            item.nat,
                                            index + 22
                                          );
                                          setBetIndex(item.sid);
                                        }}>
                                        <b> {item.nat.split(" ")[1]}</b>
                                      </button>
                                    )}
                                    <span
                                      className="d-block m-t-5"
                                      style={{ color: "white" }}>
                                      {thirtyTwoCardStack.map(
                                        (ele) =>
                                          ele.number === index + 22 && (
                                            <span
                                              className={`  ${
                                                ele.number == index + 22 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}>
                                              {ele.number == index + 22 &&
                                                ele.amount}
                                            </span>
                                          )
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b>Min: </b>
                                <span>{reqRes?.data?.data?.t2[21]?.min}</span>
                              </span>{" "}
                              <span>
                                <b>Max: </b>
                                <span>{reqRes?.data?.data?.t2[21]?.max}</span>
                              </span>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div className="row row5">
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.data?.data?.t2[8]?.nat.split(" ")[0]}{" "}
                                {reqRes?.data?.data?.t2[8]?.rate}
                              </h4>
                            </div>{" "}
                            <div
                              data-v-2cf8dad2=""
                              className="card-dt col-md-12 text-center m-t-10">
                              {reqRes?.data?.data?.t2
                                ?.slice(8, 21)
                                ?.map((item, index) => (
                                  <div
                                    className="m-r-5 card-image d-inline-block"
                                    key={index}>
                                    <div className="suspended position-relative">
                                      {item.gstatus == 0 && (
                                        <div className="suspended__2">
                                          <i className="fa fa-lock"></i>
                                        </div>
                                      )}
                                      <img
                                        data-v-2cf8dad2=""
                                        src={imageImports[index]}
                                        alt={item.nat}
                                        onClick={() => {
                                          // setShowModal(true);
                                          // setBetNum(index + 1);
                                          handleModal(
                                            item.rate,
                                            item.nat,
                                            index + 9
                                          );
                                          setBetIndex(item.sid);
                                        }}
                                      />
                                    </div>{" "}
                                    <div className="ubook text-center m-t-5 textss">
                                      <b style={{ color: "white" }}>
                                        {thirtyTwoCardStack.map(
                                          (ele) =>
                                            ele.number === index + 9 && (
                                              <span
                                                className={`  ${
                                                  ele.number == index + 9 &&
                                                  ele.amount >= 0
                                                    ? "success-1"
                                                    : "zero"
                                                }`}>
                                                {ele.number == index + 9 &&
                                                  ele.amount}
                                              </span>
                                            )
                                        )}
                                      </b>
                                    </div>
                                  </div>
                                ))}
                            </div>{" "}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b data-v-2cf8dad2="">Min: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.data?.data?.t2[8]?.min}
                                </span>
                              </span>{" "}
                              <span data-v-2cf8dad2="">
                                <b data-v-2cf8dad2="">Max: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.data?.data?.t2[8]?.max}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.data?.data?.t2[25]?.nat.split(" ")[0]}{" "}
                                {reqRes?.data?.data?.t2[25]?.rate}
                              </h4>
                            </div>{" "}
                            <div
                              data-v-2cf8dad2=""
                              className="card-dt col-md-12 text-center m-t-10">
                              {reqRes?.data?.data?.t2
                                ?.slice(25, 38)
                                ?.map((item, index) => (
                                  <div
                                    className="m-r-5 card-image d-inline-block"
                                    key={index}>
                                    <div className="suspended position-relative">
                                      {item.gstatus == 0 && (
                                        <div className="suspended__2">
                                          <i className="fa fa-lock"></i>
                                        </div>
                                      )}
                                      <img
                                        data-v-2cf8dad2=""
                                        src={imageImports[index]}
                                        alt={item.nat}
                                        onClick={() => {
                                          // setShowModal(true);
                                          // setBetNum(index + 1);
                                          handleModal(
                                            item.rate,
                                            item.nat,
                                            index + 26
                                          );
                                          setBetIndex(item.sid);
                                        }}
                                      />
                                    </div>{" "}
                                    <div className="ubook text-center m-t-5 textss">
                                      <b style={{ color: "white" }}>
                                        {thirtyTwoCardStack.map(
                                          (ele) =>
                                            ele.number === index + 26 && (
                                              <span
                                                className={`  ${
                                                  ele.number == index + 26 &&
                                                  ele.amount >= 0
                                                    ? "success-1"
                                                    : "zero"
                                                }`}>
                                                {ele.number == index + 26 &&
                                                  ele.amount}
                                              </span>
                                            )
                                        )}
                                      </b>
                                    </div>
                                  </div>
                                ))}
                            </div>{" "}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b data-v-2cf8dad2="">Min: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.data?.data?.t2[25]?.min}
                                </span>
                              </span>{" "}
                              <span data-v-2cf8dad2="">
                                <b data-v-2cf8dad2="">Max: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.data?.data?.t2[25]?.max}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Results-table">
                      <div className="color-game-h mt-2">
                        <div className="px-2 pt-1">
                          <p>Last 10 Results</p>
                        </div>
                      </div>
                      <div className="card rst-card py-1 d-flex">
                        <div className="rst-row d-flex">
                          {results?.data?.data?.map((result, index) => {
                            const item = reqRes?.data?.data?.t2.find(
                              (item) => item.sid === result?.result
                            );

                            return (
                              <div
                                onClick={() => {
                                  setMidRound(result.mid);
                                  CasinoGameResult_API(result.mid);
                                  setShowresultModal(true);
                                  setTrophy(item.nat[0]);
                                }}
                                className="rst-btn ms-1"
                                key={index}>
                                {item && <>{item.nat[0]}</>}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="My-Bets-table">
                      <div className="color-game-h mt-2">
                        <div className="px-2 pt-1">
                          <p>Open Bets</p>
                        </div>
                      </div>
                      {HistoryTable}
                      {/* <div className="card-table-inner-row row"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
            realTime={realTime}
            setRealTimeData={setRealTimeData}
          />
        )}
        {showresultModal && <ResultsModal />}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
}

export default DragonTiger2;
