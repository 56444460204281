import React, { useEffect, useState } from "react";
import "./Support.css";
import game from "../game_rules.json";
import { useNavigate, useParams } from "react-router-dom";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { logoutUser } from "../../Redux/Slices/authSlice";
const Support = () => {
  const [leftreferralApi, setleftreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [query, setQuery] = useState("");
  const [queryText, setText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = sessionStorage.getItem("userName");
  const jwt_token = sessionStorage.getItem("token");

  const Submit_API = async (event) => {
    event.preventDefault();
    try {
      let responce = await API.post(
        "HelpDesk",
        {
          uid: userId,
          query: query,
          querTypeText: queryText,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce;
      // console.log("Submit-->", responce);
      referral_API();
      setQuery("");
      setText("");
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.post(
        `GetHelpdeskuser`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: jwt_token,
          },
        }
      );
      console.log("AccountStateMent-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            status: item.status,
            edate: item.edate[0],
            subject: item.subject,
            reply_message: item.reply_message,
            ticket_id: item.ticket_id,
            query: item.query,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "edate" },
      { Header: "Subject", accessor: "query" },
      { Header: "Query", accessor: "subject" },
      { Header: "Admin Reply", accessor: "reply_message" },
      { Header: "Ticket Id", accessor: "ticket_id" },
    ],
  });

  function handleQuery(e) {
    const { value } = e.target;
    setQuery(DOMPurify.sanitize(value));
  }

  function handleQueryText(e) {
    const { value } = e.target;
    setText(DOMPurify.sanitize(value));
  }

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Help &amp; Support</h4>

            <div className="dates-div p-2 d-flex ">
              <div className="row">
                {/* <div className="col-md-5 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      Date:
                    </div>
                    <div className="">
                      <input
                        name="start_date"
                        id="from"
                        type="date"
                        autocomplete="off"
                        placeholder="Select date from"
                        className="date-input p-1 "
                        value="2024-02-10"
                      />
                    </div>
                  </div>
                </div> */}
                <form onSubmit={Submit_API} id="queryForm">


                  <div className="row">
                    <div className="col-md-12">
                      <div className="dis">
                        <div className="from d-flex align-items-center wids p-2">
                          <label>Subject</label>
                        </div>
                        <input
                          type="text"
                          className="form-elm"
                          id="Subject"
                          placeholder="Subject"
                          onChange={handleQuery}
                          value={query}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-1">
                      <div className="dis">
                        <div className="from d-flex align-items-center s p-2 wids">
                          <label>Your Query</label>
                        </div>

                        <textarea
                          className="form-elm"
                          id="query"
                          rows={3}
                          value={queryText}
                          onChange={handleQueryText}
                        />

                      </div>
                    </div>
                 
                    {/* <div className="row">
                      <div className="wids"></div>
                      <div className="col-md-12  mt-1">
                        <div className="flexbox p-2">
                          <div className="flex-md-10">
                            <input
                              className="size-md"
                              type="checkbox"
                              name="terms"
                              required=""
                            />
                          </div>
                          <div className="flex-md-90">
                            <span>
                              &nbsp; I Accept all the terms of use, privacy policy. I Also
                              acknowledge that, Above information is accurate &amp;
                              authentic as per my current knowledge.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-4 mt-1 p-2">
                      <button
                        type="submit"
                        className="bg-red w100 text-md color-white bdr-rad-xs pad-tp-15 pad-bt-15 pad-lt-30 pad-rt-30"
                      >
                        Submit Query
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="p-2">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;