import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateTransPassword } from "../api/updatePassTransAPI";

const initialState = {
  isSuccess: false,
  response: {},
  error: null,
};

export const updateTransPasswordData = createAsyncThunk(
  "updateTransPass/updateTransPasswordData",
  async ({ uid, oldPassword, newPassword, token }, { rejectWithValue }) => {
    try {
      const data = {
        uid,
        old_password: oldPassword,
        new_password: newPassword,
      };
      const response = await updateTransPassword(data, token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTransPassSlice = createSlice({
  name: "updateTransPass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTransPasswordData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateTransPasswordData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.error = null;
      })
      .addCase(updateTransPasswordData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateTransPassSlice.reducer;
