import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SessionTable from "./Table/SessionTable";
import { fetchSessionStackData } from "../../Redux/api/sessionStackAPI";
import { useDispatch, useSelector } from "react-redux";

const Session = ({
  setbetDetails,
  setmarketType,
  setbetType,
  handleModal,
  setcountryName,
  setrealtimeData,
  marketType,
  realtimeData,
  betType,
  data,
}) => {
  // console.log("session", data);

  const { id } = useSelector((state) => state.auth.currentMatch);

  const [dataValue, setDataValue] = useState("");

  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const dispatch = useDispatch();

  const checkValid = () => {
    if (realtimeData && marketType === "Session Market") {
      const filteredData = data[0].oddDatas?.filter(
        (item) => item.rname == dataValue?.team && item.sid == dataValue?.sid
      );

      if (betType === "back") {
        if (
          filteredData[0].sid == dataValue?.sid &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.b1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setrealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].sid == dataValue?.sid &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.l1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setrealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, data]);

  useEffect(() => {
    dispatch(
      fetchSessionStackData({
        userId: uid,
        id: id,
        mname: "Session Market",
        token,
      })
    );
  }, []);
  return (
    <>
      {data && data.length > 0 && data[0].oddDatas.length > 0 ? (
        <div className="row">
          <div className="col-md-12 sec__bg">
            <div className="Match-ods-h d-flex align-items-center">
              <p className="mx-2 mt-3 d-flex">
                Session MARKET <div className="green-live-dot ms-2 mt-2"></div>
              </p>
            </div>
            <div className="row back-lay-row_1  bg-dark ">
              <div className="col-7 "></div>
              <div className="col-5 d-flex">
                <button className="lay ">No</button>
                <button className="back ms-1">Yes</button>
              </div>
            </div>
            {data[0].oddDatas.map((item, index) => {
              return (
                <SessionTable
                  key={index}
                  tabData={item}
                  handleModal={handleModal}
                  setbetType={setbetType}
                  setmarketType={setmarketType}
                  setbetDetails={setbetDetails}
                  setcountryName={setcountryName}
                  setrealtimeData={setrealtimeData}
                  setDataValue={setDataValue}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(Session);
