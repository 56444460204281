import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import card from "../../Media/DTL2020/2.webp";
import { useParams } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import Modal from "../TeenPatti/Modal";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { useDispatch, useSelector } from "react-redux";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

function Casinowar() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const SOCKET_URL = "https://realgame-socket.nakshtech.info/";

  
  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
        username: uid,
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("32cardB==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0]);
        // console.log("32cardB", parsedData[0].reqResponse);
        setResults(parsedData[0].resultUrlResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocketCasinoWar");

    cricketMatchesSocket.on("GetCasinoGameSocketCasinoWar_FromAPI", (data) => {
      // console.log("dtl socket1==>", data);
      if (data.data.success == true) {
        // console.log("parsed", data);
        setreqRes(data);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Dealer</div>
                <div className="d-flex">
                  <div className="gamecard-div mx-1">
                    {casinoGameResult?.map(
                      (item, index) =>
                        index === 6 && (
                          <div className="gamecard-div mx-1" key={index}>
                            <img
                              src={item.image}
                              className="img-fluid"
                              alt={`Player A Card ${index}`}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center"></div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 5 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 1 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 2 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 3 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 4 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {casinoGameResult?.map(
                    (item, index) =>
                      index === 5 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="d-flex"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  // console.log("mid", reqRes?.data?.data?.t1[0]?.mid);

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );

      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails?.multipler === filteredData[0]?.b1
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: reqRes?.data?.data?.t1[0]?.mid,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <>
      <div className="col-lg-10">
        <div className="row home ">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">CASINO WAR</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID:{reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0}
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-1px",
                        marginTop: "-150px",
                      }}
                    >
                      <span></span>
                      <div>
                        {["C7"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "30px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}
                >
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "40px", bottom: "15px" }}
                    >
                      <button 
                        class="btn btn-primary"
                        style={{ cursor: "default" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 py-2">
                  <div className="dragon-column1">
                    <div className="row">
                      <table className="table-responsive">
                        <tr>
                          <th></th>
                          <th>
                            {["C1"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                          <th>
                            {["C2"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                          <th>
                            {["C3"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                          <th>
                            {["C4"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                          <th>
                            {["C5"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                          <th>
                            {["C6"].map((key, index) => {
                              const cardId = reqRes?.data?.data?.t1[0]?.[key];
                              return cardId ? (
                                <span key={index}>
                                  <img
                                    src={findCardImage(cardId) || cardBlank}
                                    alt={cardId}
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </span>
                              ) : null;
                            })}
                          </th>
                        </tr>
                        <tr>
                          <td></td>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>4</td>
                          <td>5</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>Winner</td>
                          {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                            <td className="row-btn suspended-box">
                              <span>{reqRes?.data?.data?.t2[0]?.b1}</span>
                              <div></div>
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[0]?.b1,
                                  reqRes?.data?.data?.t2[0]?.nat,
                                  1
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[0]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 1 && (
                                      <span
                                        className={`${
                                          ele.number == 1 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}

                          {reqRes?.data?.data?.t2[9]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[9]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[9]?.b1,
                                  reqRes?.data?.data?.t2[9]?.nat,
                                  10
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[9]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 10 && (
                                      <span
                                        className={`${
                                          ele.number == 10 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 10 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[18]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[18]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[18]?.b1,
                                  reqRes?.data?.data?.t2[18]?.nat,
                                  19
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[18]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 19 && (
                                      <span
                                        className={`${
                                          ele.number == 19 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 19 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[27]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[27]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[27]?.b1,
                                  reqRes?.data?.data?.t2[27]?.nat,
                                  28
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[27]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 28 && (
                                      <span
                                        className={`${
                                          ele.number == 28 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 28 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[36]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[36]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[36]?.b1,
                                  reqRes?.data?.data?.t2[36]?.nat,
                                  37
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[36]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 37 && (
                                      <span
                                        className={`${
                                          ele.number == 37 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 37 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[45]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[45]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[45]?.b1,
                                  reqRes?.data?.data?.t2[45]?.nat,
                                  46
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[45]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 46 && (
                                      <span
                                        className={`${
                                          ele.number == 46 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 46 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            Black{" "}
                            <img src={card} alt="" style={{ width: "20px" }} />
                            &nbsp;
                            <img src={card} alt="" style={{ width: "20px" }} />
                          </td>
                          {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[1]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[1]?.b1,
                                  reqRes?.data?.data?.t2[1]?.nat,
                                  2
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[1]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 2 && (
                                      <span
                                        className={`${
                                          ele.number == 2 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[10]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[10]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[10]?.b1,
                                  reqRes?.data?.data?.t2[10]?.nat,
                                  11
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[10]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 11 && (
                                      <span
                                        className={`${
                                          ele.number == 11 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 11 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[19]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[19]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[19]?.b1,
                                  reqRes?.data?.data?.t2[19]?.nat,
                                  20
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[19]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 20 && (
                                      <span
                                        className={`${
                                          ele.number == 20 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 20 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[28]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[28]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[28]?.b1,
                                  reqRes?.data?.data?.t2[28]?.nat,
                                  29
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[28]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 29 && (
                                      <span
                                        className={`${
                                          ele.number == 29 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 29 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[37]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[37]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[37]?.b1,
                                  reqRes?.data?.data?.t2[37]?.nat,
                                  38
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[37]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 38 && (
                                      <span
                                        className={`${
                                          ele.number == 38 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 38 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[46]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[46]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[46]?.b1,
                                  reqRes?.data?.data?.t2[46]?.nat,
                                  47
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[46]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 47 && (
                                      <span
                                        className={`${
                                          ele.number == 47 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 47 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            Red{" "}
                            <img src={card} alt="" style={{ width: "20px" }} />
                            &nbsp;
                            <img src={card} alt="" style={{ width: "20px" }} />
                          </td>
                          {reqRes?.data?.data?.t2[2]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[2]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[2]?.b1,
                                  reqRes?.data?.data?.t2[2]?.nat,
                                  3
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[2]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 3 && (
                                      <span
                                        className={`${
                                          ele.number == 3 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[11]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[11]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[11]?.b1,
                                  reqRes?.data?.data?.t2[11]?.nat,
                                  12
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[11]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 12 && (
                                      <span
                                        className={`${
                                          ele.number == 12 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 12 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[20]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[20]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[20]?.b1,
                                  reqRes?.data?.data?.t2[20]?.nat,
                                  21
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[20]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 21 && (
                                      <span
                                        className={`${
                                          ele.number == 21 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 21 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[29]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[29]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[29]?.b1,
                                  reqRes?.data?.data?.t2[29]?.nat,
                                  30
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[29]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 30 && (
                                      <span
                                        className={`${
                                          ele.number == 30 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 30 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[38]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[38]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[38]?.b1,
                                  reqRes?.data?.data?.t2[38]?.nat,
                                  39
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[38]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 39 && (
                                      <span
                                        className={`${
                                          ele.number == 39 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 39 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[47]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[47]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[47]?.b1,
                                  reqRes?.data?.data?.t2[47]?.nat,
                                  48
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[47]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 48 && (
                                      <span
                                        className={`${
                                          ele.number == 48 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 48 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>Odds </td>
                          {reqRes?.data?.data?.t2[3]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[3]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[3]?.b1,
                                  reqRes?.data?.data?.t2[3]?.nat,
                                  4
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[3]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 4 && (
                                      <span
                                        className={`${
                                          ele.number == 4 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[12]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[12]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[12]?.b1,
                                  reqRes?.data?.data?.t2[12]?.nat,
                                  13
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[12]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 13 && (
                                      <span
                                        className={`${
                                          ele.number == 13 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 13 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[21]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[21]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[21]?.b1,
                                  reqRes?.data?.data?.t2[21]?.nat,
                                  22
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[21]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 22 && (
                                      <span
                                        className={`${
                                          ele.number == 22 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 22 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[30]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[30]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[30]?.b1,
                                  reqRes?.data?.data?.t2[30]?.nat,
                                  31
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[30]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 31 && (
                                      <span
                                        className={`${
                                          ele.number == 31 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 31 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[39]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[39]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[39]?.b1,
                                  reqRes?.data?.data?.t2[39]?.nat,
                                  40
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[39]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 40 && (
                                      <span
                                        className={`${
                                          ele.number == 40 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 40 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[48]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[48]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[48]?.b1,
                                  reqRes?.data?.data?.t2[48]?.nat,
                                  49
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[48]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 49 && (
                                      <span
                                        className={`${
                                          ele.number == 49 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 49 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>Even </td>
                          {reqRes?.data?.data?.t2[4]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[4]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[4]?.b1,
                                  reqRes?.data?.data?.t2[4]?.nat,
                                  5
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[4]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 5 && (
                                      <span
                                        className={`${
                                          ele.number == 5 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 5 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[13]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[13]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[13]?.b1,
                                  reqRes?.data?.data?.t2[13]?.nat,
                                  14
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[13]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 14 && (
                                      <span
                                        className={`${
                                          ele.number == 14 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[22]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[22]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[22]?.b1,
                                  reqRes?.data?.data?.t2[22]?.nat,
                                  23
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[22]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 23 && (
                                      <span
                                        className={`${
                                          ele.number == 23 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 23 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[31]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[31]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[31]?.b1,
                                  reqRes?.data?.data?.t2[31]?.nat,
                                  32
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[31]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 32 && (
                                      <span
                                        className={`${
                                          ele.number == 32 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 32 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[40]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[40]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[40]?.b1,
                                  reqRes?.data?.data?.t2[40]?.nat,
                                  41
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[40]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 41 && (
                                      <span
                                        className={`${
                                          ele.number == 41 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 41 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[49]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[49]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[49]?.b1,
                                  reqRes?.data?.data?.t2[49]?.nat,
                                  50
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[49]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 50 && (
                                      <span
                                        className={`${
                                          ele.number == 50 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 50 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={
                                "https://versionobj.ecoassetsservice.com/v13/static/front/img/icons/spade.png"
                              }
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </td>
                          {reqRes?.data?.data?.t2[5]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[5]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[5]?.b1,
                                  reqRes?.data?.data?.t2[5]?.nat,
                                  6
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[5]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 6 && (
                                      <span
                                        className={`${
                                          ele.number == 6 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 6 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[14]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[14]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[14]?.b1,
                                  reqRes?.data?.data?.t2[14]?.nat,
                                  15
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[14]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 15 && (
                                      <span
                                        className={`${
                                          ele.number == 15 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[23]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[23]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[23]?.b1,
                                  reqRes?.data?.data?.t2[23]?.nat,
                                  24
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[23]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 24 && (
                                      <span
                                        className={`${
                                          ele.number == 24 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 24 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[32]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[32]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[32]?.b1,
                                  reqRes?.data?.data?.t2[32]?.nat,
                                  33
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[32]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 33 && (
                                      <span
                                        className={`${
                                          ele.number == 33 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 33 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[41]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[41]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[41]?.b1,
                                  reqRes?.data?.data?.t2[41]?.nat,
                                  42
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[41]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 42 && (
                                      <span
                                        className={`${
                                          ele.number == 42 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 42 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[50]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[50]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[50]?.b1,
                                  reqRes?.data?.data?.t2[50]?.nat,
                                  51
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[50]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 51 && (
                                      <span
                                        className={`${
                                          ele.number == 51 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 51 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        {/* Heart */}
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={
                                "https://versionobj.ecoassetsservice.com/v13/static/front/img/icons/heart.png"
                              }
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </td>
                          {reqRes?.data?.data?.t2[7]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[7]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[7]?.b1,
                                  reqRes?.data?.data?.t2[7]?.nat,
                                  8
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[7]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 8 && (
                                      <span
                                        className={`${
                                          ele.number == 8 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 8 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[16]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[16]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[16]?.b1,
                                  reqRes?.data?.data?.t2[16]?.nat,
                                  17
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[16]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 17 && (
                                      <span
                                        className={`${
                                          ele.number == 17 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[25]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[25]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[25]?.b1,
                                  reqRes?.data?.data?.t2[25]?.nat,
                                  26
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[25]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 26 && (
                                      <span
                                        className={`${
                                          ele.number == 26 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 26 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[34]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[34]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[34]?.b1,
                                  reqRes?.data?.data?.t2[34]?.nat,
                                  35
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[34]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 35 && (
                                      <span
                                        className={`${
                                          ele.number == 35 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 35 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[43]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[43]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[43]?.b1,
                                  reqRes?.data?.data?.t2[43]?.nat,
                                  44
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[43]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 44 && (
                                      <span
                                        className={`${
                                          ele.number == 44 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 44 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[52]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[52]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[52]?.b1,
                                  reqRes?.data?.data?.t2[52]?.nat,
                                  53
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[52]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 53 && (
                                      <span
                                        className={`${
                                          ele.number == 53 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 53 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={
                                "https://versionobj.ecoassetsservice.com/v13/static/front/img/icons/club.png"
                              }
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </td>
                          {reqRes?.data?.data?.t2[6]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[6]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[6]?.b1,
                                  reqRes?.data?.data?.t2[6]?.nat,
                                  7
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[6]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 7 && (
                                      <span
                                        className={`${
                                          ele.number == 7 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 7 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[15]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[15]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[15]?.b1,
                                  reqRes?.data?.data?.t2[15]?.nat,
                                  16
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[15]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        className={`${
                                          ele.number == 16 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[24]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[24]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[24]?.b1,
                                  reqRes?.data?.data?.t2[24]?.nat,
                                  25
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[24]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 25 && (
                                      <span
                                        className={`${
                                          ele.number == 25 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 25 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[33]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[33]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[33]?.b1,
                                  reqRes?.data?.data?.t2[33]?.nat,
                                  34
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[33]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 34 && (
                                      <span
                                        className={`${
                                          ele.number == 34 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 34 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[42]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[42]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[42]?.b1,
                                  reqRes?.data?.data?.t2[42]?.nat,
                                  43
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[42]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 43 && (
                                      <span
                                        className={`${
                                          ele.number == 43 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 43 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[51]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[51]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[51]?.b1,
                                  reqRes?.data?.data?.t2[51]?.nat,
                                  52
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[51]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 52 && (
                                      <span
                                        className={`${
                                          ele.number == 52 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 52 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        {/* Diamond */}
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={
                                "https://versionobj.ecoassetsservice.com/v13/static/front/img/icons/diamond.png"
                              }
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </td>
                          {reqRes?.data?.data?.t2[8]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[8]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[8]?.b1,
                                  reqRes?.data?.data?.t2[8]?.nat,
                                  9
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[8]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 9 && (
                                      <span
                                        className={`${
                                          ele.number == 9 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 9 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[17]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[17]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[17]?.b1,
                                  reqRes?.data?.data?.t2[17]?.nat,
                                  18
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[17]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 18 && (
                                      <span
                                        className={`${
                                          ele.number == 18 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 18 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[26]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[26]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[26]?.b1,
                                  reqRes?.data?.data?.t2[26]?.nat,
                                  27
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[26]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 27 && (
                                      <span
                                        className={`${
                                          ele.number == 27 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 27 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[35]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[35]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[35]?.b1,
                                  reqRes?.data?.data?.t2[35]?.nat,
                                  36
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[35]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 36 && (
                                      <span
                                        className={`${
                                          ele.number == 36 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 36 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[44]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[44]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[44]?.b1,
                                  reqRes?.data?.data?.t2[44]?.nat,
                                  45
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[44]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 45 && (
                                      <span
                                        className={`${
                                          ele.number == 45 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 45 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                          {reqRes?.data?.data?.t2[53]?.gstatus == 0 ? (
                            <td className="suspended-box">
                              {reqRes?.data?.data?.t2[53]?.b1}
                            </td>
                          ) : (
                            <td
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[53]?.b1,
                                  reqRes?.data?.data?.t2[53]?.nat,
                                  54
                                );
                              }}
                              className="row-btn"
                            >
                              <span>{reqRes?.data?.data?.t2[53]?.b1}</span>
                              <div className="text-center text-success">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 54 && (
                                      <span
                                        className={`${
                                          ele.number == 54 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == 54 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item?.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {<>R</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>
          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}

          {showresultModal && <ResultsModal />}
        </div>
      </div>
    </>
  );
}

export default Casinowar;
