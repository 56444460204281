import React from "react";
import { useState, useEffect } from "react";
import "./Login.css";
import { fetchIP, selectIP } from "../../Redux/Slices/ipSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Authenticate,
  loginUser,
  logoutUser,
} from "../../Redux/Slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo1 from "../../Media/realgame24.png";

import DataModal from "./PswdModal";
const Login = () => {
  const [UserName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modalData, setModalData] = useState({});
  const [pswdModal, setPswdModal] = useState(false);
  const [InfoModal, setInfoModal] = useState(false);
  const [Txnpswd, setTxnPswd] = useState("");
  const [newpswd, setNewPswd] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ip = useSelector(selectIP);
  const { user, isLoading } = useSelector((state) => state.auth);
  // console.log(ip);

  const UserInfoModal = () => {
    return (
      <>
        <div
          onClick={() => {
            handleInfoModal();
          }}
          className="Info-modal-wrapper"
        ></div>
        <div className="Info-modal-container">
          <div className="head-border d-flex align-items-center">
            <p className="my-auto mx-2">Account Information</p>
          </div>
          <div className="container-body ">
            <div className="ms-3">
              <p className="mt-2">User Id :{UserName}</p>
              <p className="mt-2">Login Password:{newpswd}</p>
              <p className="mt-2">Transaction Password :{Txnpswd}</p>
            </div>
          </div>
          <div className="modal-footer row mt-5">
            <div className="col-5"></div>
            <div className="col-7 py-2">
              <button
                onClick={() => {
                  handleInfoModal();
                }}
                className="mbb "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handlePswdModal = () => {
    setPswdModal(false);
  };

  useEffect(() => {
    dispatch(fetchIP());
  }, [dispatch]);

  const submitLogin = (e) => {
    e.preventDefault();
    // let body = encryptdata({
    //   username: UserName,
    //   password: UserName,
    //   ipaddress: ip,
    // });
    dispatch(
      loginUser({
        isAuth: false,
        username: UserName,
        password: password,
        ipaddress: ip,
      })
    );
  };

  // console.log("123",pswdModal)

  useEffect(() => {
    if (user) {
      let responce = user.data.result;
      // console.log("res", user.data);
      if (responce === "Successfull") {
        if (user.data.loginstatus === "0") {
          setPswdModal(true);
          setModalData({ ...user.data, token: user?.token });
        } else {
          toast.success("Logged In Successfully");
          let userdata = user.data.resultid;
          let token = user.token;
          let username = user.data.resultusername;
          // console.log("token", token);
          sessionStorage.setItem("token", token);
          dispatch(Authenticate());
          // console.log("userdataID", userdata);
          sessionStorage.setItem("userData", user.data.resultid);
          // console.log("userdataname", username);
          sessionStorage.setItem("userName", user.data.resultusername);
          // dispatch(fetchuserBalance({ id: user.data.resultid, token }));
          // dispatch(fetchBetButton({ id: user.data.resultid, token }));

          navigate("/");
        }
        // console.log("loggedin");
      } else {
        toast.error(responce);
        dispatch(logoutUser());
      }
    }
  }, [user]);

  const handleInfoModal = () => {
    setInfoModal(false);
    setNewPswd("");
    setTxnPswd("");
    navigate("/");
  };
  return (
    <div className="background-img">
      <div className="d-flex justify-content-center align-items-center">
        <div className="card login-card  mt-5">
          <div className="d-flex justify-content-center mt-4">
            <img className="img-fluid img-div" src={logo1} alt="a" />
          </div>

          <div className="px-4">
            <form onSubmit={submitLogin}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  className=" login-input p-1 px-2"
                  id="exampleInputEmail1"
                  placeholder="Enter UserName"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  maxlength={6}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password*
                </label>
                <input
                  type="password"
                  className=" login-input p-1 px-2"
                  placeholder="Enter your password"
                  id="exampleInputPassword1"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  maxlength={20}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn login-btn mt-2">
                  Login
                </button>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <div className="bth d-flex justify-content-center mt-2">
                      Back to &nbsp;{" "}
                      <span>
                        <a className="nav-link" href="/">
                          {" "}
                          Home
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <div className="bth d-flex justify-content-center mt-2">
                      Create Account &nbsp;{" "}
                      <span>
                        <a className="nav-link" href="/registration">
                          {" "}
                          Sign Up
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="bth d-flex justify-content-center mt-2">
                      Forget Password &nbsp;{" "}
                      <span>
                        <a className="nav-link" href="/ForgetPassword">
                          {" "}
                          Reset My Password
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {pswdModal === true && (
          <DataModal
            data={modalData}
            onClose={handlePswdModal}
            setTxnPswd={setTxnPswd}
            Txnpswd={Txnpswd}
            setNewPswd={setNewPswd}
            newpswd={newpswd}
            setInfoModal={setInfoModal}
          />
        )}
        {InfoModal && <UserInfoModal onClose={handleInfoModal} />}
      </div>
    </div>
  );
};

export default Login;
