import axios from 'axios';
import { API } from '../../API/API';

const withdrawHistoryAPI = axios.create();

export const fetchUserWithdrawlHistory = async (id, token) => {
  try {
    const response = await API.get(`UserWithdrawlHistory?uid=${id}`,  {
      headers: {
        Authorization: token,
      },
    });
    // console.log("userwithdraw", response );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default withdrawHistoryAPI