import React, { useEffect, useState } from "react";
import "../Deposit/Deposit.css";
import { useDispatch } from "react-redux";
import { API } from "../../API/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import DOMPurify from "dompurify";

const FundRequestUPI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = sessionStorage.getItem("user");
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const UserName = sessionStorage.getItem("userName");
  const [showSidebar, setShowSidebar] = useState(false);
  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState("");
  const [txn, setTxn] = useState("");
  const [bankDetails, setbankDetails] = useState([]);
  const [AdminBankDetail, setAdminBankDetail] = useState([]);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const Bank_Detail_API = async () => {
    try {
      let responce = await API.get(
        `adminUpiDetails?uid=${UserName}`,

        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Bank Details", responce);
      setbankDetails(responce.data.data[0]);
    } catch (e) {
      console.log("error while calling fuction sell", e);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    //Bank_Detail_API();
    bankDetailbyId(3);
  }, []);

  const handleSelect = (e) => {
    const { value } = e.target;
    setBankName(value);
    bankDetailbyId(value);
  };

  const bankDetailbyId = async (id) => {
    try {
      let responce = await API.get(
        `/adminBankDetailsByBankid?id=${id}&uid=${UserName}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Bank Details By Id", responce);
      setAdminBankDetail(responce.data.data[0]);
    } catch (e) {
      console.log("error while calling fuction sell", e);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const handleAmount = (e) => {
    let value = DOMPurify.sanitize(e.target.value);
    value = value.replace(/[^0-9]/gi, "").substring(0, 8);
    setAmount(value);
  };
  const handleTxn = (e) => {
    let value = DOMPurify.sanitize(e.target.value);
    value = value.replace(/[^A-Z0-9]/gi, "").substring(0, 30);
    setTxn(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!bankName) {
        toast.error("Select Bank Name");
        return;
      } else if (!amount || amount <= 0) {
        toast.error("Invalid Amount");
        return;
      } else if (!txn) {
        toast.error("Invalid UTR No.");
        return;
      }
      let responce = await API.post(
        `/upiFundRequest`,
        {
          uid: userId,
          username: UserName,
          amount: amount,
          txn: txn,
          bankid: bankName,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Submit API", responce);
      if (responce.data.data === "success") {
        toast.success("Requested Successfully !!");
        setAmount("");
        setTxn("");
        setBankName("");
        setAdminBankDetail([]);
      } else {
        toast.error(responce.data.data);
      }
    } catch (e) {
      console.log("error while calling fuction sell", e);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  }
  return (
    <>
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 px-2 p-1">
            <div className="funds-transfer-main-div ">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">Deposit UPI Request</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="card deposit-card mg-lg-4 mt-2">
                  <div className="px-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-2">
                        <div
                          className="col-md-6 px-1"
                          style={{ display: "none" }}
                        >
                          <div className="form-group">
                            <label className="" htmlFor="bankName">
                              UPI Type
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="accountNumber"
                              aria-describedby="accountNumber"
                              placeholder="UPI Id"
                              value={AdminBankDetail[0]?.btcname || ""}
                              readOnly
                            />
                            {/* <select
                              className="form-control mt-2 "
                              id="bankName"
                              value={bankName}
                              onChange={handleSelect}
                              required
                            >
                              <option disabled value="">
                                Select UPI Type
                              </option>
                              {bankDetails?.map((element) => (
                                <option key={element.id} value={element.id}>
                                  {element.btcname}
                                </option>
                              ))}
                            </select> */}
                          </div>
                        </div>
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label className="" htmlFor="accountNumber">
                              {" "}
                              UPI Id
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="accountNumber"
                              aria-describedby="accountNumber"
                              placeholder="UPI Id"
                              value={AdminBankDetail[0]?.btcaddress || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12 px-1">
                          <div className="form-group">
                            <label className="" htmlFor="ifsc">
                              {" "}
                              UPI QR Code
                            </label>
                            <br />
                            <img
                              className="img"
                              width="200px"
                              height="200px"
                              src={`${AdminBankDetail[0]?.imglink}`}
                            ></img>
                          </div>
                        </div>

                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="amount">Request Amount</label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="amount"
                              placeholder="Request Amount"
                              value={amount}
                              onChange={(e) => handleAmount(e)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="txn">UTR No.</label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="txn"
                              placeholder="UTR No."
                              value={txn}
                              onChange={(e) => handleTxn(e)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn deposit-f-btn mt-lg-4 mt-2  "
                      >
                        Request
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundRequestUPI;
