import React, { useEffect, useState } from "react";
import "./RightSidebar.css";
import Maincard from "./Maincard";
import SmallCards from "./SmallCards";
import { API } from "../../API/API";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useNavigate } from "react-router-dom";

const RightSidebar = () => {
  const uid = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [news, setNews] = useState([]);

  async function News_API() {
    try {
      let res = await API.post(
        `/userNews`,
        {
          id: 0,
          content: "",
          var: 5,
          username: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("news_APi", res);
      if (res?.data?.data?.record[0]?.length > 0) {
        setNews(res?.data?.data?.record[0]);
      }
    } catch (error) {
      console.log("Error News", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  }
  // console.log("news", news);
  useEffect(() => {
    if (uid) {
      News_API();
    } else {
      setNews([]);
    }
  }, [uid]);
  return (
    <div className="col-lg-4 L-sidebar d-lg-block d-none">
      <div className="left-innerbar">
        <div className="leftbar-h ms-1 ">
          <p className="ms-3 pt-3 pb-0 mb-1"> LIVE CASINO GAMES</p>
        </div>
        <Maincard />
        <div className="leftbar-h ms-1 ">
          <p className="ms-3 pt-3 pb-0 mb-1">LATEST NEWS</p>
        </div>
        {news && news.length > 0 && (
          <marquee
            width="100%"
            direction="up"
            scrollamount={2}
            height="200px"
            behavior="scroll"
          >
            {news.map((item, index) => (
              item.content
            ))}
          </marquee>
        )}
      </div>
    </div>
  );
};

export default RightSidebar;
